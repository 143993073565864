import React, { Component } from 'react';
import { useState } from 'react';
import './StyleSheet.scss';
import LinearProgress from '@mui/material/LinearProgress';
function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
}

export class SberPaySucces extends Component {

    static displayName = SberPaySucces.name;

constructor(props) {
    super(props);
    
    const search = window.location.search;
    const params = new URLSearchParams(search);

    if (params == undefined || params.count < 3) {  window.location.href = "/"; }
    const user =    params.get('user');
    const resultCode = params.get('r');
    const orderId = params.get('mdOrder');
	
    const gt =      params.get('goto');

    this.state =
    {
        loading: true,
        code: 1000,
        user: user,
        r: resultCode,
        orderId: orderId,
        gotoend:gt,
    };
    console.log(this.state);
    //console.log(Object.fromEntries(params));
/*    this.handleInputChange = this.handleInputChange.bind(this);*/
    }
    // Like on Open this page method
    componentDidMount() {
        if (this.state.r!=0) this.Done();
      /* SberPaySucces.check()*/
    }

    render() {
       
        return (
               <div className="Doc_view_payment_window">
                {
                    this.state.code === 1000 ?
                        <div>
                            <div className="title">
                                Загрузка...
                                <LinearProgress color="secondary" />
                            </div>

                            <div className="title">
                                
                            </div>
                        </div> :
                        this.state.code === 200 ?
                            <div>
                                <div className="title">
                                    Оплата прошла успешно!
                                </div>

                                <div className="title">
                                    Ожидаем поступление денежных средств от банка.
                                </div>
                            </div>
                            :
                            this.state.code === 204 || this.state.r==0 ?
                                <div>
                                    <div className="title">
                                        Оплата была отменена
                                    </div>

                                    <div className="title">
                                        
                                    </div>
                                </div> :
                                <div>
                                    <div className="title">
                                        ...
                                    </div>

                                    <div className="title">

                                    </div>
                                </div>
                }
            </div>
        )
    }

    static check() {
        window.location.replace('https://localhost:44410/fetch-data');
    }
    async Done() {
        //const respons = await fetch('sber' + '/' + this.state.Doc + '?summ=' + (this.state.balance < 0 ? -this.state.balance : 2.25));
        fetch((process.env.REACT_APP_USE_API_URL == true ? process.env.REACT_APP_API_URL : '') + 'sber?user=' + this.state.user + '&orderid=' + this.state.orderId)
            .then(async (response) => {
                this.setState({ code: response.status });
                await timeout(15500);
                window.location.assign("/" + this.state.gotoend);
                
               
            }).catch(e => console.error('EXCEPTION: ', e))

    }
    
}