import React, { Component, useState } from 'react';
import './StyleSheet.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import { json } from '../../node_modules/react-router-dom/dist/index';
import axios from 'axios';
import { Alert } from 'bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PasswordChecklist from "react-password-checklist";
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import Collapse from 'react-bootstrap/Collapse';
import Fade from 'react-bootstrap/Fade';
import Feedback from 'react-bootstrap/Feedback'
//import DatePicker from 'react-date-picker';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { FormCheck } from '../../node_modules/react-bootstrap/esm/index';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ruLocale from 'date-fns/locale/ru';




registerLocale('ru', ruLocale)


const StorageKey = 'features-color-theme';
function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
}
const toDate = (dateStr) => {
    const [day, month, year] = dateStr.split(".")
    return new Date(year, month - 1, day)
}
const rowsPerPage = 20;
setDefaultLocale('ru');
function Date2String(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}
function DateString2StringSQl(date) {
    const d = new Date(date);
    return `${d.getFullYear()}-${(d.getMonth() + 1 >= 10 ? "" : "0")}${d.getMonth() + 1}-${(d.getDate() + 1 >= 10 ? "" : "0")}${d.getDate()}`
}
function DateString2String(date) {
    const d = new Date(date);
    return `${(d.getDate() + 1 >= 10 ? "" : "0")}${d.getDate()}.${(d.getMonth() + 1 >= 10 ? "" : "0")}${d.getMonth() + 1}.${d.getFullYear()}`
}
function GetStatusString(statusCode) {
    switch (statusCode) {
        case undefined:
        case null:
            return "Ожидает обработки";
            break;

    }
}
function ChangePassModal(props) {
    const [password, setPassword] = useState("")
    const [Pass, setPass] = useState("")
    const [passwordAgain, setPasswordAgain] = useState("")
    const [errors, setErrors] = useState({})
    const [isNotValid, setValid] = useState("")

    const handleSubmit = (ev) =>
    {
        let e = 0;
        if (props.pass == Pass) {
            setErrors({ ...errors, CurrentPass: null })
            e++;
        }
        else setErrors({ ...errors, CurrentPass: 'Неверный текущий пароль' })

        if (isNotValid) {
            setErrors(ers => { return { ...ers, NewPass: 'Пароль должен соответствовать шаблону' } })
            e++;
            console.log("Is not valid")
        }
        else setErrors(ers => { return { ...ers,NewPass: null } })

        if (e === 0) props.onSubmit(ev);
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Смена пароля
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form >
                    <Form.Group>
                        <FloatingLabel controlId="pas1" label="текущий пароль">
                            <Form.Control type="password" onChange={(e) => setPass(e.target.value)} placeholder="пароль" isInvalid={!!errors.CurrentPass} />
                            <Form.Control.Feedback type="invalid">{errors.CurrentPass}</Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>

                    <div className="space" />
                    <div className="space" />
                    <div className="space" />
                    <div className="space" />
                    <Form.Group>
                        <FloatingLabel controlId="newpas1" label="Новый пароль">
                            <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} isInvalid={!!errors.NewPass} />
                            <Form.Control.Feedback type="invalid">{errors.NewPass}</Form.Control.Feedback>
                        </FloatingLabel>
                        <div className="space" />
                        <FloatingLabel controlId="newpas2" label="Подтверждение пароля">
                            <Form.Control type="password" onChange={e => setPasswordAgain(e.target.value)} />
                        </FloatingLabel>
                        <div className="space" />
                    </Form.Group>
                    <PasswordChecklist
                        rules={["minLength", "specialChar", "number", "capital", "match"]}
                        minLength={6}
                        value={password}
                        valueAgain={passwordAgain}
                        messages={{
                            minLength: "Пароль должен содержать минимуи 6 символов",
                            specialChar: "Должен содержать специальный символ (!№;%# ...)",
                            number: "Должен содержать цифру",
                            capital: "Должен содержать заглавную и мальеную букву",
                            match: "Пароли должны совпадать",
                        }}
                            onChange={(IsValid) => { setValid(!IsValid) }}
                            />
                    <Modal.Footer>
                        <Button onClick={handleSubmit}>Сменить</Button>
                    </Modal.Footer>
                </Form>

            </Modal.Body>

        </Modal>
    );
}
export class UrUserDocs extends Component {


    static displayName = UrUserDocs.name;

    constructor(props) {
        super(props);
        this.state =
        {
            data: [],
            documents: [],
            messages: [],
            contacts: undefined,
            nonReadedMessages:0,
            Doc: '1',
            loading: true,
            failedToLoad: false,
            balance: 0,
            activePage: 0,
            activeLayout: 0,
            IsAuthorizationCorrect: false,
            Guid:'NONE',
            IsDocFinded: false,
            saldo:0,
            PayWindowActive: false,
            DocDate: "1999-09-09",
            DocumentsState: 0,
            Title: "comp",
            Inn: "############",
            Kpp: "###########",
            titleBank   :"####",
            korSchet    :"####",
            gorod       :"####",
            raschetSchet:"####",
            bik: "####",

            contact2Deprecate: {},

            currentMessage:0,
            isMessageWindowOpen: false,
            changepass: false,
            currentPass: "",
            isthemeDark: false,
            isKaContactsLoading: false,
            isInProfileEditMode: true,



        };
        //#region EVENT INIT
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePayButton = this.handlePayButton.bind(this);
        this.handleButClick = this.handleButClick.bind(this);
        this.handleArrowClick = this.handleArrowClick.bind(this);
        this.AuthorizationSubmit = this.AuthorizationSubmit.bind(this);
        this.CompletePayButton = this.CompletePayButton.bind(this);
        this.handlecancellButtonClick = this.handlecancellButtonClick.bind(this);
        this.AuthorizationButtonClickCheat = this.AuthorizationButtonClickCheat.bind(this);
        this.DocumentClick = this.DocumentClick.bind(this);
        this.SetDocumentFilter = this.SetDocumentFilter.bind(this);
        this.handleMessageButtonClick = this.handleMessageButtonClick.bind(this);
        this.handleChangePass = this.handleChangePass.bind(this);
        this.loadTheme = this.loadTheme.bind(this);
        
        //#endregion
    }
    async loadTheme() {
        let theme = localStorage.getItem(StorageKey);
        theme = theme == "true";
        if (!theme) {
            localStorage.setItem(StorageKey, 'false');
            theme = false;
        }
        await this.setState({ isthemeDark: !!theme });
        document.documentElement.setAttribute('data-theme',
            !!theme ? "dark" : "light");
    }
    //#region events
    handleMessageButtonClick(event, message) {

        let ids = [...this.state.messages];
        let index = ids.findIndex(el => el.id == message);
        ids[index].isReaded = true;

        this.setState({ messages: ids, nonReadedMessages: this.state.nonReadedMessages-1 });  
        this.SendReadMessage(message);
        
    }
    handlePayButton(event) {
        this.setState({ PayWindowActive: true });
        event.preventDefault();

        this.SberButton();
    }
    handleChangePass(event) {
        event.preventDefault();
        console.log("PASSWORD CHANGE")
    }
    SetDocumentFilter(event, ShowActive) {
        event.preventDefault();
        var doc = this.state.documents.find(v => v.isActive != ShowActive);
        if (doc != null && doc != undefined)
        this.DocumentClick(event, doc);

        this.setState({ ShownerdActiveDocs: ShowActive, data: undefined, activeLayout: 0 })
    }
    CompletePayButton(event) {
        this.setState({ PayWindowActive: false });
        //console.log(event.target.elements);
        //console.log(event.target.elements.summ.value);
        //console.log(event.target.elements.email.value);
        event.preventDefault();
        this.SberButton(event.target.elements.email.value,event.target.elements.summ.value);
    }
    componentDidMount() {
        //this.loadData();
    }
    handlecancellButtonClick(event) {
        event.preventDefault();
        this.setState({ PayWindowActive: false });
    }
    handleInputChange(event) {
        this.setState({
            Doc: event.
                target.
                value.
                replace(/\D/g, '')
        });
    };
    AuthorizationSubmit(event) {
        //console.log(event.target.elements.login.value);
        //console.log(event.target.elements.pass.value);
        this.setState({ currentPass: event.target.elements.pass.value });
        this.CheckLogAPass(event.target.elements.login.value, event.target.elements.pass.value)
        event.preventDefault();
    };
    AuthorizationButtonClickCheat(event) {
        event.preventDefault();
        this.setState({loading: false, balance:-11111111, failedToLoad: false, IsDocFinded: true, DocDate:"12-12-1200" });
    };

    DocumentClick(event,doc) {
        event.preventDefault();
        if (doc != null && doc != undefined)
        if (doc !== this.state.Doc.ndoc) {
           // console.log(doc == undefined || doc.date == undefined ? new Date().toISOString() : new Date(Date.parse(doc.date)).toISOString());
            console.log(toDate(doc.date));
            this.setState({
                Doc: doc, objects: undefined, isObjectfailed2Load: false,
                DateFrom: (doc == undefined || doc.date == undefined ? new Date() : toDate(doc.date))
                , DateTo: new Date()
            });
            this.loadData(doc.guid);
        }
    }

    handleButClick(i,event) {
        event.preventDefault();
        this.setState({ activePage: i });
    }
    handleArrowClick(i, event) {
        event.preventDefault();
        const l = this.state.data.length;
        const d = Math.floor((l-1) / rowsPerPage);
        let s = this.state.activePage + i;
        if (s > d) s = d;
        if (s < 0) s = 0;
        this.setState({ activePage: s });
    }
    //#endregion
    //#region renders
    static renderAuntheficaionPage(This) {
        return(
        <div className="Doc_search_page">
            <div className="Doc_search_container">
                <div className="Doc_search_logo">
                        <img src={require('../Images/logo-inner-4.png')} alt="logo-inner-4.png" />
                    </div>
                    <form action="#" className="Doc_search_form" onSubmit={This.AuthorizationSubmit}>


                <div className="field">
                            <input type="text" name="login" placeholder="Логин" className="input" />
                        <label className="label">
                            Логин
                            </label>
                        </div>
                        <div className="field">
                            <input type="password" name="pass" placeholder="Пароль" className="input" />
                        <label className="label">
                            Пароль
                            </label>
                        </div>
                        <div className="field_centre">
                            <button className="Doc_authorization_button" type="submit">
                                <span>Войти</span>
                            </button>
                            {/*<button className="Doc_authorization_button" onClick={This.AuthorizationButtonClickCheat}>*/}
                            {/*    <span>Войти просто так (TEST)</span>*/}
                            {/*</button>*/}
                        </div>
                    </form>

                    {UrUserDocs.renderNotFoundMessage(This)}
                    
                <div className="Doc_search_subtext">
                        {/*Чтобы узнать свой баланс и оплатить услуги, введите номер договора*/}
                        <a href="https://drive.google.com/file/d/1ByJ0EE9ZhP9b9FSftjHdZUIQZmXGUuvg/view?usp=sharing" target="_blank" rel="nofollow" className="OffertLink">
               
                            Оферта  

                        </a>
                    </div>
                <div className="Doc_search_bot">
                    <a href="https://sibtko.ru/" rel="nofollow" className="Doc_search_link">
                        <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 0v2H2v8h8V8h2v4H0V0h4zm8 0v6h-2V3.5L5.5 8.003 4 6.5 8.5 2H6V0h6z" fillRule="evenodd" />
                        </svg>
                        Вернуться на главную
                    </a>
                    <a href="mailto:feedback@sibtko.ru" target="_blank" rel="nofollow" className="Doc_search_link">
                        <svg width="14" height="12" viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 4v6.636c0 .375-.971 1.364-1.34 1.364H1.34C.97 12 0 11.011 0 10.636V4l7 4 7-4zm-2-4c1.333 0 2 .667 2 2v1.003L7 7 0 3.003V2C0 .667.667 0 2 0z" ll-rule="nonzero" />
                        </svg>
                        feedback@sibtko.ru
                    </a>
                    <a href="tel:+78006001420" className="Doc_search_link">
                        <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.993 11.049a.677.677 0 01-.207.593l-1.971 1.957a1.11 1.11 0 01-.349.253c-.143.069-.284.113-.422.133l-.089.008c-.05.004-.113.007-.193.007-.187 0-.491-.032-.911-.096-.42-.065-.934-.223-1.541-.475-.608-.252-1.297-.63-2.068-1.135-.77-.504-1.59-1.196-2.46-2.076a16.248 16.248 0 01-1.72-1.957A13.949 13.949 0 01.967 6.533a9.309 9.309 0 01-.623-1.439c-.138-.43-.232-.8-.281-1.112a3.676 3.676 0 01-.06-.734c.01-.178.015-.277.015-.297.02-.138.065-.279.134-.422.069-.144.153-.26.252-.349L2.373.208A.653.653 0 012.849 0c.129 0 .242.037.34.111.1.074.184.166.253.275l1.586 3.01a.722.722 0 01.074.52.925.925 0 01-.252.474l-.726.727a.247.247 0 00-.052.096.366.366 0 00-.022.111c.04.208.128.445.266.712.119.237.302.526.549.868.247.34.597.734 1.052 1.179.445.454.84.808 1.186 1.06.345.252.634.437.867.556.232.119.41.19.533.215l.185.037c.02 0 .052-.007.097-.022a.247.247 0 00.096-.052l.845-.86a.908.908 0 01.622-.237c.168 0 .302.03.4.089h.015l2.86 1.69c.208.129.332.292.371.49z" ll-rule="evenodd" />
                        </svg>
                        8 800 600 14 20
                    </a>
                    </div>
                </div>
            </div>
        )
    }
    static renderNotFoundMessage(This) {

        if (This.state.IsDocNotFined == true)

        return (
            <div className="Doc_seatch_notfound">
                <svg width="45" height="40" viewBox="0 0 45 40" xmlns="http://www.w3.org/2000/svg">
                    <g fill-rule="nonzero" fill="none">
                        <path d="M44.177 31.186L27.534 2.845C26.457 1.073 24.584 0 22.5 0c-2.084 0-3.957 1.073-5.034 2.845L.823 31.186c-1.077 1.843-1.1 4.034-.047 5.876C1.83 38.904 3.726 40 5.857 40h33.286c2.13 0 4.028-1.096 5.081-2.938 1.054-1.842 1.03-4.033-.047-5.876z" fill="#475364" /> <path d="M42.01 32.258L25.496 4.186C24.87 3.124 23.73 2.5 22.5 2.5c-1.231 0-2.37.624-2.997 1.686L2.991 32.258a3.412 3.412 0 00-.023 3.487 3.432 3.432 0 003.02 1.755h33.024a3.432 3.432 0 003.02-1.755 3.412 3.412 0 00-.023-3.487z" fill="#FFF500" /> <path d="M22.5 32.5a1.877 1.877 0 01-1.875-1.875c0-1.034.841-1.875 1.875-1.875s1.875.841 1.875 1.875A1.877 1.877 0 0122.5 32.5zM23.75 25c0 .833-.42 1.25-1.26 1.25-.84 0-1.253-.417-1.24-1.25V10c-.013-.833.4-1.25 1.24-1.25s1.26.417 1.26 1.25v15z" fill="#475364" />
                    </g>
                </svg>
                <div className="text"><div className="title">
            Договор не найден
        </div>
            Проверьте правильно ли введен номер договора и повторите попытку поиска
            </div></div>
            )
        else return(<div></div>)

    }
    static renderMainFrame(This) {
        let content;
        switch (This.state.activeLayout) {
            case 0: content = UrUserDocs.renderDocumentPage(This); break;
            case 1: content = UrUserDocs.renderRequestsPage(This); break;
            case 2: content = UrUserDocs.renderRequisitesPage(This); break;
            case 3: content = UrUserDocs.renderMessagesPage(This); break;
            case 4: content = UrUserDocs.renderADOPage(This); break;
            case 5: content = UrUserDocs.renderProfilePage(This); break;
        }
        return (
            <div className="Doc_page_layout">
                {UrUserDocs.renderHat(This)}
                <div className="flex">
                    {UrUserDocs.renderLeftPanel(This)}
                    {content}
                </div >
                
                {this.renderBottomInfo(This)}
            </div>
        );
                
    }
    static renderProfilePage(This) {
        if (!This.state.isKaContactsLoading && This.state.contacts == undefined) {
            This.setState({ isKaContactsLoading: true });
            This.LoadProfileInfo();
        }
       // { console.log(This.state.ADO); }
        return (<div className="profile_page_layout">
            <Modal
                show={This.state.show}
                onHide={() => { This.setState({ show: false }) }}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*<div className="deprecate_contract_message">Почему контакт '{This.state.contact2Deprecate.value}' более не актуален?</div>*/}
                    <div className="deprecate_contract_message">Укажите причину удаления контакта '{This.state.contact2Deprecate.value}'</div>
                    <div className="space_8"/>
                    <FloatingLabel controlId="floatingTextarea2" label="Коментарий:">
                        <Form.Control value={This.state.modal_deprecate_commentary} onChange={(e, v) => { console.log(e.target.value); This.setState({ modal_deprecate_commentary: e.target.value }) }} name="textarea" as="textarea" placeholder="коментарий..." rows="3" />
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { This.setState({ show: false }) }}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={() => { This.DeprecateContact(This.state.modal_deprecate_commentary); }} disabled={This.state.isContactsLoading} >
                        {This.state.isContactsLoading ? <div><Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          
                        /> Отправляем...</div>: <span>Получить</span>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={This.state.ShowAddContactModal}
                onHide={() => { This.setState({ ShowAddContactModal: false }) }}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Указать новый контакт</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FloatingLabel controlId="floatingContact" label="Контакт:">
                        <Form.Control value={This.state.modal_addContact_contact} onChange={(e, v) => {This.setState({ modal_addContact_contact: e.target.value }) }}
                            name="Contact" type="text" placeholder="Контакт..." rows="3" />
                    </FloatingLabel>
                    <div className="space_8" />
                    <FloatingLabel controlId="floatingTextarea2" label="Коментарий:">
                        <Form.Control value={This.state.modal_deprecate_commentary} onChange={(e, v) => {This.setState({ modal_deprecate_commentary: e.target.value }) }}
                            name="textarea" as="textarea" placeholder="коментарий..." rows="3" />
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { This.setState({ ShowAddContactModal: false }) }}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={() => { This.AddNewContact(This.state.modal_addContact_contact,This.state.modal_deprecate_commentary); }} disabled={This.state.isContactsLoading} >
                        {This.state.isContactsLoading ? <div><Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"

                        /> Отправляем...</div> : <span>Подтвердить</span>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            {/*<div className="title">*/}
            {/*    {This.state.Title}*/}
            {/*</div>*/}
            {/*<div class="line"></div>*/}
            <Tabs
                defaultActiveKey="profile-info"
                id="justify-tab-example"
                className="mb-3"
                //justify
            >
                <Tab eventKey="profile-info" title="Информация">
                    <div className="profile_page_values_layout">

                        <div>
                            {/*     <span className="profile_value_title">Юрид. адрес:</span> ООО «Чистый Город Кемерово», 650036, г. Кемерово, ул. Мирная, 9, офис 110*/}
                        </div>
                        <div className="profile_page_container">
                            <div className="no_Wrap">

                                <div className="horizontal_group">
                                    <span className="profile_value_title">
                                        Полное наименование:&nbsp;
                                    </span>
                                    {This.state.Title}
                                </div>
                                <div className="horizontal_group">
                                    <span className="profile_value_title">
                                        Краткое наименование:&nbsp;
                                    </span>
                                    {This.state.contacts.info.title_small}
                                </div>
                                <div className="horizontal_group">
                                    <span className="profile_value_title">
                                        Юр. адрес:&nbsp;
                                    </span>
                                    {This.state.contacts.info.address}
                                </div>
                                <div className="horizontal_group">
                                    <span className="profile_value_title">
                                        ИНН/КПП: &nbsp;
                                    </span>
                                    {This.state.Inn}&nbsp;/&nbsp;{This.state.Kpp}
                                </div>
                                <div className="horizontal_group">
                                    <span className="profile_value_title">
                                        ОГРН:&nbsp;
                                    </span>
                                    {This.state.contacts.info.ogrn}
                                </div>
                                <div className="horizontal_group">
                                    <span className="profile_value_title">
                                        Эдо:&nbsp;
                                    </span>
                                    {UrUserDocs.renderADOPage(This)}
                                </div>
                            </div>

                            <div className="horizontal_group">

                           
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="Contacts" title="Контакты">
                    <div className="listTable profile_list_background">
                        <div className="">
                            <span className="profile_value_title horizontal_group space_between">Контакты:
                                {/*<button className="profile_edit_contacts_btn" onClick={(e) => {*/}
                                {/*This.setState({ isInProfileEditMode: !This.state.isInProfileEditMode })*/}
                                {/*}}>{This.state.isInProfileEditMode ? <span>Назад</span> : <span>Редактировать</span>}</button>*/}
                                <button className="profile_edit_contacts_btn" onClick={(e) => { This.setState({ ShowAddContactModal: true }) }}><svg fill="#54D930" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    width="12px" height="12px" viewBox="0 0 45.402 45.402"
                                >
                                    <g>
                                        <path d="M41.267,18.557H26.832V4.134C26.832,1.851,24.99,0,22.707,0c-2.283,0-4.124,1.851-4.124,4.135v14.432H4.141
		c-2.283,0-4.139,1.851-4.138,4.135c-0.001,1.141,0.46,2.187,1.207,2.934c0.748,0.749,1.78,1.222,2.92,1.222h14.453V41.27
		c0,1.142,0.453,2.176,1.201,2.922c0.748,0.748,1.777,1.211,2.919,1.211c2.282,0,4.129-1.851,4.129-4.133V26.857h14.435
		c2.283,0,4.134-1.867,4.133-4.15C45.399,20.425,43.548,18.557,41.267,18.557z"/>
                                    </g>
                                </svg> &nbsp;&nbsp;Добавить</button>
                            </span>
                        </div>
                        {This.state.contacts == undefined ?
                            <Spinner animation="grow" size="sm" /> :
                            <div className="profile_value_list  custom_form_control">
                                {This.state.contacts == undefined ?
                                    <></>
                                    : <>
                                        {This.state.contacts.emails.map((d, i) => {
                                            return <div key={d.value} className="value_list_value_contaienr">

                                                <span className="contact_value">{d.value}</span><div className="space_8" />
                                                {This.state.isInProfileEditMode ? <button className="contact_deprecate" onClick={(e) => { This.setState({ show: true, contact2Deprecate: d }) }}>
                                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" x="0px" y="0px" viewBox="0 0 43.263 43.263">
                                                        <g>
                                                            <path d="M42.877,10.742c0,0-0.829-4.474-3.731-7.086s-7.415-3.655-7.415-3.655L21.632,10.478L11.533,0 c0,0-3.639,1.237-6.928,4.333s-4.219,6.409-4.219,6.409l10.495,10.889L0.386,32.521c0,0,0.93,4.418,4.703,7.514 c3.773,3.095,6.443,3.228,6.443,3.228l10.099-10.478l10.1,10.477c0,0,3.544,0.159,7.125-3.325c3.581-3.483,4.021-7.417,4.021-7.417 L32.382,21.63L42.877,10.742z">
                                                            </path>
                                                        </g>
                                                    </svg>
                                                </button> : <></>
                                                }
                                            </div>
                                        })
                                        } </>

                                }
                                {
                                    This.state.contacts.phones.sort((a, b) => {
                                        if (a.isActive == b.isActive) return 0;
                                        if (a.isActive && !b.isActive) return 1;
                                        if (!a.isActive && b.isActive) return -1;
                                    }).map((d, i) => {
                                        return <div className="value_list_value_contaienr">

                                            <span className="contact_value">{d.value}</span>
                                            {This.state.isInProfileEditMode ? <button className="contact_deprecate" onClick={(e) => { This.setState({ show: true, contact2Deprecate: d }) }}>
                                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" x="0px" y="0px" viewBox="0 0 43.263 43.263">
                                                    <g>
                                                        <path d="M42.877,10.742c0,0-0.829-4.474-3.731-7.086s-7.415-3.655-7.415-3.655L21.632,10.478L11.533,0 c0,0-3.639,1.237-6.928,4.333s-4.219,6.409-4.219,6.409l10.495,10.889L0.386,32.521c0,0,0.93,4.418,4.703,7.514 c3.773,3.095,6.443,3.228,6.443,3.228l10.099-10.478l10.1,10.477c0,0,3.544,0.159,7.125-3.325c3.581-3.483,4.021-7.417,4.021-7.417 L32.382,21.63L42.877,10.742z">
                                                        </path>
                                                    </g>
                                                </svg>
                                            </button> : <></>
                                            }
                                        </div>
                                    })
                                }

                            </div>
                        }

                    </div>

                </Tab>
            </Tabs>
        

        </div>);
    }
    static renderRequestsPage(This) {

        if (This.state.requests == undefined && !This.state.isRequestsLoading) {
            if (!This.state.isRequestsfailed2Load) This.LoadUserRequsets();
        }

        return (
            <div className="requests_layout">
                <div className="listTable width100">
                    <div className="requests_scroll_container">
                        
                        {
                            This.state.isRequestsLoading ?
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"

                                /> :
                                This.state.requests == undefined ? <span>Что-то пошло не так Ж(</span>
                                    :This.state.requests.map((d, i) => {
                                        return <div className="request_row">

                                            <div className="horizontal_group space_between">
                                                <div className="horizontal_group">
                                                    <div className="request_status" >
                                                        <span className="request_status_label">
                                                            {GetStatusString(d.status)}
                                                        </span>
                                                    </div>
                                                    <span className="request_type">{d.type} по договору</span>&nbsp;
                                                    <span className="request_docn">{d.ndoc}</span>&nbsp;
                                                    за период с&nbsp;<span className="request_dateRange">{d.dateFrom}&nbsp;</span>&nbsp;по&nbsp;
                                                    <span className="request_dateRange">{d.dateTo}</span>&nbsp;
                                                    <span className="request_email">&nbsp;на&nbsp;{d.email}</span>
                                                </div>
                                                <span className="request_date">Заказано:&nbsp;{d.date}</span>
                                            </div>
                            </div>
                        })}
                    </div>
                </div>
                
        </div>);
    }
    static renderRequisitesPage(This) {
        const svgg = (
            < svg width = '24' height = '24' viewBox = '0 0 48 48' xmlns = 'http://www.w3.org/2000/svg' xlink = 'http://www.w3.org/1999/xlink' ><rect width='24' height='24' stroke='none' fill='#000000' opacity='0' />
                    <path d="M 15 0 C 13.90625 0 13 0.90625 13 2 L 6 2 C 4.355469 2 3 3.355469 3 5 L 3 41 C 3 42.644531 4.355469 44 6 44 L 36 44 C 37.644531 44 39 42.644531 39 41 L 39 5 C 39 3.355469 37.644531 2 36 2 L 29 2 C 29 0.90625 28.09375 0 27 0 Z M 15 2 L 27 2 L 27 5 C 27 5.566406 26.566406 6 26 6 L 16 6 C 15.433594 6 15 5.566406 15 5 Z M 6 4 L 13 4 L 13 5 C 13 6.644531 14.355469 8 16 8 L 26 8 C 27.644531 8 29 6.644531 29 5 L 29 4 L 36 4 C 36.554688 4 37 4.445313 37 5 L 37 41 C 37 41.554688 36.554688 42 36 42 L 6 42 C 5.445313 42 5 41.554688 5 41 L 5 5 C 5 4.445313 5.445313 4 6 4 Z M 21 14 L 21 16 L 23 16 L 23 14 Z M 25 14 L 25 16 L 27 16 L 27 14 Z M 29 14 L 29 16 L 31 16 L 31 14 Z M 33 14 L 33 16 L 35 16 L 35 14 Z M 41 14 L 41 16 L 43 16 L 43 14 Z M 45 14 L 45 16 L 47 16 L 47 14 Z M 21 18 L 21 20 L 23 20 L 23 18 Z M 45 18 L 45 20 L 47 20 L 47 18 Z M 21 22 L 21 24 L 23 24 L 23 22 Z M 45 22 L 45 24 L 47 24 L 47 22 Z M 21 26 L 21 28 L 23 28 L 23 26 Z M 45 26 L 45 28 L 47 28 L 47 26 Z M 21 30 L 21 32 L 23 32 L 23 30 Z M 45 30 L 45 32 L 47 32 L 47 30 Z M 21 34 L 21 36 L 23 36 L 23 34 Z M 45 34 L 45 36 L 47 36 L 47 34 Z M 21 38 L 21 40 L 23 40 L 23 38 Z M 45 38 L 45 40 L 47 40 L 47 38 Z M 45 42 L 45 44 L 47 44 L 47 42 Z M 21 46 L 21 48 L 23 48 L 23 46 Z M 25 46 L 25 48 L 27 48 L 27 46 Z M 29 46 L 29 48 L 31 48 L 31 46 Z M 33 46 L 33 48 L 35 48 L 35 46 Z M 37 46 L 37 48 L 39 48 L 39 46 Z M 41 46 L 41 48 L 43 48 L 43 46 Z M 45 46 L 45 48 L 47 48 L 47 46 Z" stroke-linecap="round" />
            </svg >)
        return (<div className="layout_offest">


            {/*<Dropdown.Menu className="drpdown_menu" show>*/}
            {/*    <Dropdown.Header>*/}
            {/*        Реквизиты для оплаты*/}
            {/*    </Dropdown.Header>*/}
            {/*        <Dropdown.Item onClick={(e) => { navigator.clipboard.writeText(This.state.titleBank); e.preventDefault(); }}>*/}
            {/*        {This.state.titleBank} ({This.state.gorod}) { svgg}*/}
            {/*        </Dropdown.Item>*/}
            {/*        <Dropdown.Divider />*/}
            {/*        <Dropdown.Item onClick={(e) => { navigator.clipboard.writeText(This.state.raschetSchet); e.preventDefault(); }}>*/}
            {/*        Р/С: {This.state.raschetSchet} {svgg}*/}
            {/*        </Dropdown.Item>*/}
            {/*        <Dropdown.Item onClick={(e) => { navigator.clipboard.writeText(This.state.korSchet); e.preventDefault(); }}>*/}
            {/*        К/С: {This.state.korSchet} {svgg}*/}
            {/*        </Dropdown.Item>*/}
            {/*        <Dropdown.Item onClick={(e) => { navigator.clipboard.writeText(This.state.bik); e.preventDefault(); }}>*/}
            {/*        БИК: {This.state.bik} {svgg}*/}
            {/*        </Dropdown.Item> */}
            {/*    </Dropdown.Menu>*/}
            {/*<Dropdown.Menu className="drpdown_menu" show>*/}
            <div className="requsites_panel">
                <Dropdown.Header className="requsites_label">
                    Реквизиты для оплаты
            </Dropdown.Header>
            <div className="line"/>
                <div onClick={(e) => { navigator.clipboard.writeText(This.state.titleBank); e.preventDefault(); }}>
                    {This.state.titleBank} ({This.state.gorod}) 
                </div>
                <Dropdown.Divider />
                <div onClick={(e) => { navigator.clipboard.writeText(This.state.raschetSchet); e.preventDefault(); }}>
                    Р/С: {This.state.raschetSchet} 
                </div>
                <div onClick={(e) => { navigator.clipboard.writeText(This.state.korSchet); e.preventDefault(); }}>
                    К/С: {This.state.korSchet} 
                </div>
                <div onClick={(e) => { navigator.clipboard.writeText(This.state.bik); e.preventDefault(); }}>
                    БИК: {This.state.bik} 
                </div>
            </div>
            {/*</Dropdown.Menu>*/}
        </div>);
    }
    static renderMessagesPage(This) {
       // console.log(This.state.messages);
        const msgs = This.state.messages;
        return (
            <div className="messages_layout">
                <div className="space"/>
                {UrUserDocs.renderMessagePanel(This)}
                {msgs.map((d, i) =>
                    <div key={d.id} className={"message" + (i == 3 /*d.isReaded == "False"*/ ?"": "_readed")} onClick={(e) => { This.setState({ isMessageWindowOpen: true, currentMessage: i }); if (d.isReaded=="False") This.handleMessageButtonClick(e, d.id) }} show>
                        {/*<Dropdown.Header>*/}
                        {/*    Реквизиты для оплаты*/}
                        {/*</Dropdown.Header>*/}
                      
                        <div className="message_container" >
                            <div className="message_icon">
                            {i==3/*d.isReaded == "False"*/ ? <svg fill="#272F3C" height="17px" width="17px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 455.862 455.862">
                                        <path d="M441.088,63.154H14.774C6.615,63.154,0,69.77,0,77.93v300.003c0,8.16,6.615,14.775,14.774,14.775h426.313
			c8.16,0,14.775-6.614,14.775-14.775V77.93C455.862,69.77,449.248,63.154,441.088,63.154z M403.394,316.659
			c6.256,5.43,6.926,14.903,1.497,21.16c-5.43,6.254-14.901,6.928-21.161,1.496c-3.876-3.364-101.683-88.252-105.452-91.523
			l-40.515,35.164c-2.82,2.448-6.326,3.672-9.832,3.672s-7.012-1.224-9.832-3.672l-40.515-35.164
			c-3.77,3.272-101.576,88.159-105.452,91.523c-6.257,5.43-15.731,4.761-21.161-1.496c-5.43-6.257-4.76-15.73,1.497-21.16
			L154.7,227.93L52.468,139.203c-6.256-5.43-6.926-14.903-1.497-21.16c5.431-6.256,14.904-6.928,21.161-1.496
			c5.07,4.4,146.594,127.231,155.799,135.22c7.972-6.919,150.305-130.451,155.799-135.22c6.256-5.431,15.731-4.762,21.161,1.496
			c5.43,6.257,4.76,15.731-1.497,21.16L301.162,227.93L403.394,316.659z"/>
                                </svg>
                                : <svg fill="#475364" height="17px" width="17px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                        <path class="st0" d="M18.71,245.495c-0.081,1.455-0.208,2.91-0.208,4.374v183.444c0,13.31,3.335,25.825,9.163,36.813l137.513-139.7
		L18.71,245.495z"/>
                                        <path class="st0" d="M493.291,245.495l-146.468,84.931l137.513,139.7c5.828-10.988,9.162-23.504,9.162-36.813V249.869
		C493.498,248.405,493.372,246.95,493.291,245.495z"/>
                                        <path class="st0" d="M256,383.089l-66.01-38.277L44.996,492.084C58.894,504.436,77.137,512,97.189,512h317.622
		c20.052,0,38.296-7.564,52.193-19.916L322.01,344.812L256,383.089z"/>
                                        <path class="st0" d="M256,350.993l144.968-84.055v-86.503H111.042v86.503L256,350.993z M173.879,212.829h163.464v21.588H173.879
		V212.829z M173.879,259.094h81.732v21.587h-81.732V259.094z"/>
                                        <path class="st0" d="M83.282,152.676h345.445v98.168l57.669-33.442c-3.47-7.645-8.087-14.784-13.897-21.045L313.687,25.166
		C298.796,9.117,277.895,0,256,0c-21.894,0-42.795,9.117-57.686,25.166L39.502,196.356c-5.811,6.262-10.428,13.4-13.898,21.045
		l57.678,33.442V152.676z"/>
                                </svg>
                            }
                            </div>
                            <div className="message_left">
                                <div className="message_title">
                                    {d.header}
                                </div>
                            </div>
                           
                            <div className="message_right">
                                {d.message.slice(0, 40) + "..."}
                                <div className="date_group">
                                    <div className="message_date create_date">
                                        {d.createDate}
                                    </div>
                                    <div className="message_date read_date">
                                        {/*{d.readDate}*/}
                                    </div>
                                    {/*{d.isReaded != "False" ? "✔" : "?"}*/}
                                </div>
                               
                            </div>
                            
                        </div>
                       {/* <div className="line" />*/}
                       
                    </div>
                )}
                {msgs.length <= 0?<div> Сообщений нет</div>:<></>

                }
            </div>
        );
    }
    static renderADOPage(This) {
        if (   (This.state.ADO == "" || This.state.ADO.toLowerCase() == "нет") )
            return (
                <div id="ЭДО" className="layout_offest layout_ado">
                <span className="ado_promotion">
                    Электронный документооборот это современный способ обмена документами между компаниями. Переход на электронный документооборот даёт организации ряд безусловных преимуществ, таких как снижение трат на бумагу, оргтехнику, расходные материалы к принтерам и т. д. Также важна экономия времени, поскольку любой документ можно найти в системе, получить или отправить практически мгновенно. Существенно снижается срок создания, согласования, утверждения документации. Не только в соседний офис, а в любой город или регион. Соответственно, повышается эффективность работы сотрудников. При этом пересылаемые данные защищены от несанкционированного доступа. Важным плюсом является отсутствие надобности хранить гигантский бумажный архив.
                    </span>
                        <div className="ado_request_label">
                        
                        <li>ООО "Компания "Тензор"&emsp;<b>2BE449e500f940f4868bbff7da6dbc5aa78</b></li>
                        <li>КСБ Контур&emsp;<b>2BM-4205284801-420501001-201502040733168902190</b></li>
                        Вы можете самостоятельно сформировать приглашения в ЭДО по следующим идентификаторам, либо оформить запрос:
                        <div className="space" />
                        <Form onSubmit={(event) => {
                            event.preventDefault();
                            // console.log(event.target.elements);
                            This.SendAdoRequest(event.target.elements.select1.value, event.target.elements.textarea.value)
                        }}>
                            <FloatingLabel  controlId="select1" label="Оператор ЭДО">
                                <Form.Select name="select1" aria-label="Default select example">
                                    <option value="КОНТУР">КОНТУР</option>
                                    <option value="СБИС">СБИС</option>
                            </Form.Select>
                        </FloatingLabel>
                        <div className="space" />
                     <FloatingLabel  controlId="floatingTextarea2" label="Коментарий:">
                    
                                <Form.Control name="textarea" as="textarea" placeholder="коментарий..." rows="3" />
                        </FloatingLabel>
                            <div className="space" />
                            <div className="ado_btn_container">
                                <button className="nav_button ado_btn">Отправить заявку</button>
                            </div>
                            </Form>
                    </div>
                    
                </div>);
        return (
            <div id="ЭДО" className="layout_offest">
                Ваша Компания обменивается электронными документами с ООО "Чистый Город Кемерово" посредством провайдера {This.state.ADO}
            </div>);
    }
    static renderDocumentPage(This) {
        if (!This.state.isKaContactsLoading && This.state.contacts == undefined) {
            This.setState({ isKaContactsLoading: true });
            This.LoadProfileInfo();
        }
        let contents = {};
        if (This.state.loading
            ? This.state.failedToLoad
                ? <p><em>Failed to load...</em></p>
                : <p><em>Loading...</em></p>
            : This.state.data == undefined || This.state.data == null) contents = <></>
        else {
            contents = UrUserDocs.renderDocTable(This,This.state.data, This.state.activePage);
            switch (This.state.active_doc_page) {
                case "obj":
                    contents = UrUserDocs.renderObjectsPage(This);
                    break;
                case "op":
                    contents = UrUserDocs.renderDocTable(This, This.state.data, This.state.activePage);
                    break;
            }
        }


                
        if (This.state.failedToLoad) This.loadData(This.state.Doc.guid);
        /*   This.setState({ activePage:0 })*/
     //   const switchPage = This.state.data == undefined || This.state.data == null ? <></> : UrUserDocs.renderPageSwitch(This, This.state.data, This.state.activePage);
        return (
            <div className="flex width100">
                {UrUserDocs.renderDocumetList(This)}

                <Modal
                    show={This.state.show_receiveMessageWindow}
                    onHide={() => { This.setState({ show_receiveMessageWindow: false, showDetailemailModal :false}) }}
                    backdrop="static"
                    keyboard={false}
                >
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        // let code = "CompareRep";
                        let email = This.state.showDetailemailModal ? e.target.elements.email.value : null;
                        // if (email == "NONE") email = e.target.elements.email.value;
                        let dateFrom = This.state.DateFrom;
                        let dateTo = This.state.DateTo; //console.log(This.state.Doc);
                        let DocGuid = This.state.Doc.guid;
                        This.SendDocRequest(
                            //code, 
                            email,
                            dateFrom, dateTo
                            , DocGuid
                        )

                    }}>
                        <Modal.Header closeButton>

                            <Modal.Title>{This.state.ReceiveMessageTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="modal_text">Укажите период</div>
                            С&nbsp;&nbsp;<DatePicker required name="DateFrom" selectsStart
                                onChange={(v) => { This.setState({ DateFrom: v }) }} selected={This.state.DateFrom}
                                dateFormat="dd.MM.yyyy"
                                startDate={This.state.DateFrom} endDate={This.state.DateFrom} maxDate={This.state.DateTo} />
                            &nbsp;&nbsp;По&nbsp;&nbsp;<DatePicker required name="DateTo" selectsEnd
                                onChange={(v) => { This.setState({ DateTo: v }) }} selected={This.state.DateTo}
                                dateFormat="dd.MM.yyyy"
                                startDate={This.state.DateFrom} endDate={This.state.DateFrom} minDate={This.state.DateFrom} />


                        </Modal.Body>
                        <Modal.Footer>

                            <Button variant="secondary" onClick={() => { This.setState({ show_receiveMessageWindow: false, showDetailemailModal: false }) }}>
                                {!This.state.isContactsLoading ? "Отмена" : "Закрыть"}
                            </Button>
                            <Button variant="primary" type="submit" className="main_button" disabled={This.state.isContactsLoading}>
                                {This.state.isContactsLoading ? <div><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"

                                /> Отправляем...</div> : <span>{ This.state.showDetailemailModal ? "Заказать":"Получить"}</span>
                                }
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
                <Modal
                    show={This.state.showDetailemailModal}
                    onHide={() => { This.setState({ showDetailemailModal: false, show_receiveMessageWindow: false }) }}
                    backdrop="static"
                    keyboard={false}
                >
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        // let code = "CompareRep";
                        let email = This.state.showDetailemailModal ? e.target.elements.email.value : null;
                        // if (email == "NONE") email = e.target.elements.email.value;
                        let dateFrom = This.state.DateFrom;
                        let dateTo = This.state.DateTo; //console.log(This.state.Doc);
                        let DocGuid = This.state.Doc.guid;
                        This.SendDocRequest(
                            //code, 
                            email,
                            dateFrom, dateTo
                            , DocGuid
                        )

                    }}>
                        <Modal.Header closeButton>

                            <Modal.Title>{This.state.ReceiveMessageTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="tip_text">Извините, но сейчас мы не можем предоставить эту информацию.</div>
                            <div className="modal_text">Введите Email</div>
                            <div className="space_8" />
                            <InputGroup hasValidation >
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="    E-mail"
                                    className="mb-3"
                                >
                                    <Form.Control required
                                        type="email" name="email" placeholder="E-mail" className="input" onChange={(e, v) => { This.setState({ user_email: v }) }} value={This.state.user_email} />
                                    <Form.Control.Feedback type="invalid">
                                        Введите почту или выберите уже известную
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </InputGroup>
                            <div className="space_8" />
                            <div className="modal_text">Или выберите уже известный</div>
                            <Form.Select name="email_select" aria-label="" disabled={This.state.contacts == undefined} onChange={(e) => { if (e.target.value != "NONE") This.setState({ user_email: e.target.value }); }}>
                                <option value="NONE">-</option>
                                {
                                    !(This.state.isKaContactsLoading || This.state.contacts == undefined) ?
                                        This.state.contacts.emails.map((v, i) => {
                                            return <option id={v.guid} value={v.value}>{v.value}</option>
                                        }) : <></>
                                }
                            </Form.Select>
                            {
                                (This.state.isKaContactsLoading && This.state.contacts == undefined) ? <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"

                                /> : <></>
                            }
                            <div className="tip_text">*На указанную почту прийдет заказанный вами документ</div>

                        </Modal.Body>
                        <Modal.Footer>

                            <Button variant="secondary" onClick={() => { This.setState({ showDetailemailModal: false, show_receiveMessageWindow: false }) }}>
                                {!This.state.isContactsLoading ? "Отмена" : "Закрыть"}
                            </Button>
                            <Button variant="primary" type="submit" className="main_button" disabled={This.state.isContactsLoading}>
                                {This.state.isContactsLoading ? <div><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"

                                /> Отправляем...</div> : <span>{This.state.showDetailemailModal ? "Заказать" : "Получить"}</span>
                                }
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
                <div className="short width100">
                    {
                        This.state.data != undefined && This.state.data!=null?
                            <div className="documentbar_page_layout">
                                <Tabs
                                    defaultActiveKey="accruals"
                                    id="justify-tab-example"
                                    className="mb-3"
                                    //justify
                                >
                                    <Tab className="fullsize_tab" eventKey="accruals" title="Операции">
                                        <header className="ur_Doc_view_header">
                                            <div className="ur_Doc_view_header_container">

                                            <div className="ur_Doc_view_current_doc_label">
                                                <div className="horizontal_group width100 ur_Doc_view_current_doc_container">
                                                    <div className="ur_doc_header_title">
                                                        <span>
                                                            Просматриваемый договор
                                                        </span>
                                                        <div className="Doc_view_current_doc_label_doc">
                                                            № {This.state.Doc.ndoc} от {
                                                                //   DateString2String(
                                                                This.state.DocDate
                                                                //   )
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="Doc_view_current_buttons_container">
               
                                                            <button className="request_docs_btn" onClick={() => { 
															This.setState({ show_receiveMessageWindow: true, ReceiveMessageTitle: "Акт сверки по договору №" +   This.state.Doc.ndoc });
															ym(95667411,'reachGoal','act-sverki');
															}}>
                                                            {/*<svg width="20" height="14" xmlns="http://www.w3.org/2000/svg">*/}
                                                            {/*    <path d="M18 0H2C.667 0 0 .667 0 2v10c0 1.333.667 2 2 2h16c1.327 0 1.99-.667 1.99-2 .002 0 .006-3.333.01-10 0-1.333-.667-2-2-2zM9.5 4h-7c-.333 0-.5-.167-.5-.5 0-.333.167-.5.5-.5h7c.333 0 .5.167.5.5 0 .333-.167.5-.5.5zm0 3h-7c-.333 0-.5-.167-.5-.5 0-.333.167-.5.5-.5h7c.333 0 .5.167.5.5 0 .333-.167.5-.5.5zM17 9c.667 0 1 .333 1 1s-.333 1-1 1H3c-.667 0-1-.333-1-1s.333-1 1-1zm-1-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"*/}
                                                            {/*        fillRule="nonzero" />*/}
                                                            {/*</svg>*/}
                                                                Акт сверки
                                                        </button>


                                                    </div>
                                                    {/*<div className="doc_view_header_buttons_container">*/}
                                                    {/*    <button className="simple_icon_button" onClick={() => { This.setState({ active_doc_page: 'obj' }) }}>*/}
                                                    {/*        <svg width="22" height="22" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">*/}
                                                    {/*            <g id="Ikon">*/}
                                                    {/*                <path d="m26 16.978v8.022a3.00328 3.00328 0 0 1 -3 3h-2a3.00328 3.00328 0 0 1 -3-3v-2a1.0013 1.0013 0 0 0 -1-1h-2a1.0013 1.0013 0 0 0 -1 1v2a3.00328 3.00328 0 0 1 -3 3h-2a3.00328 3.00328 0 0 1 -3-3v-8.022a2.981 2.981 0 0 1 1.15771-2.36768l8.22852-6.3999a1.00117 1.00117 0 0 1 1.22754 0l8.228 6.39941a2.98165 2.98165 0 0 1 1.15823 2.36817zm3.60791-2.772-11.83591-9.06342a3.00116 3.00116 0 0 0 -3.66113.01074l-11.68753 9.05615a.99993.99993 0 0 0 1.22466 1.58106l11.6875-9.05615a1.00226 1.00226 0 0 1 1.2207-.00391l11.83589 9.06353a1 1 0 0 0 1.21582-1.5879z">*/}
                                                    {/*                </path>*/}
                                                    {/*            </g>*/}
                                                    {/*        </svg>*/}
                                                    {/*        Объекты*/}
                                                    {/*    </button>*/}
                                                    {/*    <button className="simple_icon_button" onClick={() => { This.setState({ active_doc_page: 'op' }) }}>*/}
                                                    {/*        <svg width="22" height="22" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.985 511.985">*/}
                                                    {/*            <g>*/}
                                                    {/*                <path d="M455.492,141.445c-3.101-16.123-12.723-30.249-26.59-39.04c-13.805-8.878-30.662-11.664-46.59-7.7l-252.08,61.74 c-7.005,1.574-11.408,8.528-9.834,15.533c1.574,7.005,8.528,11.408,15.533,9.834c0.161-0.036,0.321-0.075,0.481-0.118 l252.09-61.71c18.28-4.459,36.714,6.746,41.173,25.026c0.116,0.476,0.222,0.953,0.317,1.434 c1.615,6.996,8.596,11.357,15.592,9.742C452.252,154.647,456.585,148.201,455.492,141.445z"></path>*/}
                                                    {/*                <path d="M352.362,30.845c-16.147-28.981-52.73-39.386-81.711-23.239c-0.793,0.442-1.576,0.902-2.349,1.379l-223.83,138.14 c-6.093,3.768-7.989,11.755-4.24,17.86c3.772,6.108,11.782,8.001,17.89,4.23l223.87-138.13 c16.011-9.884,37.004-4.917,46.888,11.094c0.267,0.433,0.525,0.872,0.772,1.316c3.61,6.206,11.567,8.311,17.774,4.701 C353.47,44.681,355.65,37.015,352.362,30.845z"></path>*/}
                                                    {/*                <path d="M433.152,203.755H78.802c-30.814,0.05-55.777,25.026-55.81,55.84v196.53c0.022,30.822,24.988,55.81,55.81,55.86h354.38 c30.816-0.055,55.777-25.034,55.81-55.85v-196.54C488.954,228.771,463.976,203.793,433.152,203.755z M462.992,456.135 c-0.016,16.463-13.347,29.811-29.81,29.85H78.802c-16.467-0.039-29.799-13.393-29.81-29.86v-196.53 c0.022-16.471,13.369-29.818,29.84-29.84h354.35c16.459,0.039,29.788,13.381,29.81,29.84V456.135z"></path>*/}
                                                    {/*                <path d="M147.142,253.175c-7.006-1.588-13.972,2.804-15.56,9.81c-5.642,24.554-24.816,43.728-49.37,49.37 c-7.003,1.583-11.397,8.544-9.813,15.547c1.339,5.923,6.601,10.13,12.673,10.133v0.02c0.969,0.001,1.935-0.106,2.88-0.32 c34.321-7.879,61.121-34.679,69-69C158.54,261.729,154.148,254.763,147.142,253.175z"></path>*/}
                                                    {/*                <path d="M156.932,446.985c-7.881-34.32-34.68-61.119-69-69c-7.003-1.585-13.965,2.807-15.55,9.81 c-1.585,7.003,2.807,13.965,9.81,15.55l0.01,0.01c24.554,5.642,43.728,24.816,49.37,49.37c1.585,7.003,8.547,11.395,15.55,9.81 C154.125,460.95,158.517,453.988,156.932,446.985z"></path>*/}
                                                    {/*                <path d="M432.022,388.565c-2.251-6.818-9.602-10.521-16.42-8.27l0.01,0.04c-30.423,10.143-53.37,35.397-60.56,66.65 c-1.585,7.003,2.807,13.965,9.81,15.55c7.003,1.585,13.965-2.807,15.55-9.81c5.128-22.385,21.553-40.479,43.34-47.74 C430.57,402.734,434.273,395.383,432.022,388.565z"></path>*/}
                                                    {/*                <path d="M423.762,310.705c-21.788-7.254-38.221-25.339-43.36-47.72c-1.585-7.003-8.547-11.395-15.55-9.81 c-7.003,1.585-11.395,8.547-9.81,15.55c7.186,31.263,30.138,56.526,60.57,66.67c6.818,2.251,14.169-1.452,16.42-8.27 C434.283,320.307,430.58,312.955,423.762,310.705z"></path>*/}
                                                    {/*                <path d="M259.352,345.295l-0.42-0.1c-22.94-5.21-22.94-14.51-22.94-17.56c0-8.3,6.25-17.23,20-17.23 c8.055,0.001,15.323,4.833,18.44,12.26c2.778,6.627,10.403,9.748,17.03,6.97c6.627-2.778,9.748-10.403,6.97-17.03 c-5.34-12.768-16.158-22.443-29.44-26.33v-10.56c0-7.18-5.82-13-13-13c-7.18,0-13,5.82-13,13v10.27c-19.48,5-33,21.24-33,41.6 c0,12.34,5.59,34.21,42.67,42.85l0.42,0.1c22.91,5.23,22.91,14.51,22.91,17.56c0,8.3-6.25,17.23-20,17.23 c-8.055-0.001-15.323-4.833-18.44-12.26c-2.778-6.627-10.403-9.748-17.03-6.97c-6.627,2.778-9.748,10.403-6.97,17.03 c5.338,12.771,16.156,22.451,29.44,26.34v10.52c0,7.18,5.82,13,13,13c7.18,0,13-5.82,13-13v-10.3c19.46-5,33-21.24,33-41.59 C301.992,375.795,296.442,353.935,259.352,345.295z"></path>*/}
                                                    {/*                <path d="M369.382,344.865h-27.85c-7.18,0-13,5.82-13,13s5.82,13,13,13h27.85c7.18,0,13-5.82,13-13 S376.562,344.865,369.382,344.865z">*/}
                                                    {/*                </path>*/}
                                                    {/*                <path d="M170.452,344.865h-27.85c-7.18,0-13,5.82-13,13s5.82,13,13,13h27.85c7.18,0,13-5.82,13-13 S177.632,344.865,170.452,344.865z">*/}
                                                    {/*                </path>*/}
                                                    {/*            </g>*/}
                                                    {/*        </svg>*/}
                                                    {/*        Операции*/}
                                                    {/*    </button>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                            <div className="ur_Doc_view_middle_info_balance"><div className="text">
                                                Баланс по договору
                                                    <div className={"value" +((parseFloat(This.state.Doc.saldo.replace(',','.')) < 0)? " negative":" positive")} >
                                                        {

                                                            (parseFloat(This.state.Doc.saldo.replace(',', '.')) == 0 ? 0:parseFloat((This.state.Doc.saldo.replace(',', '.')))).toLocaleString()
                                                        } ₽
                                                </div>

                                                </div>
                                                    {
                                                        !This.state.docinfo.showPaybtn ?
                                                            <></>:
                                                            <button className="pay_button" onClick={This.handlePayButton}>
                                                                <svg width="20" height="14" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M18 0H2C.667 0 0 .667 0 2v10c0 1.333.667 2 2 2h16c1.327 0 1.99-.667 1.99-2 .002 0 .006-3.333.01-10 0-1.333-.667-2-2-2zM9.5 4h-7c-.333 0-.5-.167-.5-.5 0-.333.167-.5.5-.5h7c.333 0 .5.167.5.5 0 .333-.167.5-.5.5zm0 3h-7c-.333 0-.5-.167-.5-.5 0-.333.167-.5.5-.5h7c.333 0 .5.167.5.5 0 .333-.167.5-.5.5zM17 9c.667 0 1 .333 1 1s-.333 1-1 1H3c-.667 0-1-.333-1-1s.333-1 1-1zm-1-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"
                                                                        fillRule="nonzero" />
                                                                </svg>
                                                                Оплатить
                                                            </button>
                                                    }
                       

                                                </div>

                                            </div>
                                            <Box sx={{ width: '100%', height: '5px' }}>
                                                {/*<LinearProgress />*/}
                                                {This.state.isDocumentInfoLoading ?<LinearProgress />:<></>}
                                            </Box>
                                        </header>

 
                                        <div className="horizontal_group height100">
                                            <div className="width100">

                                                <div className="Doc_view_body">
                                                    <div className="ur_Doc_view_container">

                                                        <div className="listTable width100">
                                                            {/*   {switchPage}*/}
                                                            {/*{contents}*/}
                                                            
                                                            {UrUserDocs.renderDocTable(This,This.state.data, This.state.activePage)}
                                                            
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab className="fullsize_tab" eventKey="objects" title="Объекты">

                                        <div className="horizontal_group height100">
                                            <div className="listTable width100">
                                                {UrUserDocs.renderObjectsPage(This)}
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                              

                                    {/*<Table striped bordered hover>*/}
                                    {/*    <thead>*/}
                                    {/*        <tr>*/}
                                    {/*            <th>First Name</th>*/}
                                    {/*            <th>Last Name</th>*/}
                                    {/*            <th>Username</th>*/}
                                    {/*        </tr>*/}
                                    {/*    </thead>*/}
                                    {/*    <tbody>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>Mark</td>*/}
                                    {/*            <td>Otto</td>*/}
                                    {/*            <td>@mdo</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*           <td>Jacob</td>*/}
                                    {/*            <td>Thornton</td>*/}
                                    {/*            <td>@fat</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>3</td>*/}
                                    {/*            <td>Larry the Bird</td>*/}
                                    {/*            <td>@twitter</td>*/}
                                    {/*        </tr>*/}
                                    {/*    </tbody>*/}
                                    {/*</Table>*/}
                                
                            {/*{UrUserDocs.renderDocbotInfo(This)}*/}
                        </div>:<></>
                    }
                </div>
            </div >
        );

    }
    static renderDocTable(This,data, activePage) {
        const res = [];
        let subset = data
        if (data == null || data == undefined) return (
            <></>);
        //console.log(data);
//            .slice(activePage * rowsPerPage, activePage * rowsPerPage + rowsPerPage)
      //  console.log(activePage);
        return (
            <div className="Doc_view_table">
                {/*Заглавье таблицы*/}
                <div className="Doc_view_table_header">
                    <div className="Doc_view_table_column">
                        Дата
                    </div>
                    <div className="Doc_view_table_column">
                        Начисление
                    </div>
                    <div className="Doc_view_table_column">
                        Платеж
                    </div>
                    <div>Описание</div>
                </div>
                {/*Тело таблицы*/}
                {

                    data
                        //.slice(activePage * rowsPerPage, activePage * rowsPerPage + rowsPerPage)
                        .map(function (d, i) {
                            var isHaveCorrects = !(d.corects == undefined || d.corects == null || d.corects.length == 0);
                            var correctsSumm = 0;
                            const isOpen = This.state.active_doc_corrects == d;
                            if (isHaveCorrects) d.corects.forEach((v) => { correctsSumm += parseFloat(v.summ.replace(',', '.')) })
                           // console.log(correctsSumm);
                            return (<>

                                <div key={d.dt + i} className={"Doc_view_table_row" + (isHaveCorrects ? " collapsable" : "") + (isOpen ? " open" : " collapsed")} >
                                    {/*<div className="size_32">*/}
                                    {/*    {*/}
                                    {/*        !isHaveCorrects ? <></> :*/}
                                    {/*            <svg className="accrual_detail_arrow" id="Capa_1" enable-background="new 0 0 358.017 358.017" viewBox="0 0 358.017 358.017" xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*                <g><g><g><g><path d="m7.54 161.141c10.014-10.014 25.946-10.014 35.961 0l135.423 135.423 135.423-135.423c10.014-10.014 25.946-10.014 35.961 0 10.014 10.014 10.014 25.946 0 35.961l-153.404 153.404c-10.014 10.014-25.946 10.014-35.961 0l-153.403-153.403c-9.787-10.014-9.787-25.946 0-35.962z" /></g><g><path d="m7.54 7.511c10.014-10.014 25.946-10.014 35.961 0l135.423 135.423 135.422-135.423c10.014-10.014 25.946-10.014 35.961 0s10.014 25.946 0 35.961l-153.403 153.403c-10.014 10.014-25.946 10.014-35.961 0l-153.403-153.175c-9.787-10.015-9.787-26.174 0-36.189z"*/}
                                    {/*                />*/}
                                    {/*                </g></g></g></g></svg>*/}
                                    {/*    }*/}
                                    {/*</div>*/}
                                    <div className="Doc_view_table_column" >
                                        {d.dt}
                                    </div>
                                    <div className="Doc_view_table_column">
                                        <span>
                                            {d.summ <= 0 ? '–' : parseFloat(parseFloat(d.summ.replace(',', '.')) + (isOpen ? 0 : correctsSumm)).toLocaleString() + ' ₽'}
                                        </span>
                                    </div>

                                    <div className="Doc_view_table_column">
                                        {d.summ2 <= 0 ? '–' : parseFloat(d.summ2.replace(',', '.')).toLocaleString() + ' ₽'}
                                    </div>
                                    <div className="horizontal_groupwidth100 justify_content">
                                        <span className="comment">
                                            {d.comment}
                                        </span>
                                        <div className="horizontal_group width100 justify_content">
                                            {parseFloat(d.summ.replace(',', '.')) + correctsSumm <= 0 ? <></> : <button disabled={This.state.LoadingDocFile == d.id} className="request_docs_btn" 
											onClick={() => { 
											This.SendUPDRequest(d.id);
											ym(95667411,'reachGoal','upd-click');
											}}>
                                            <svg width="20" height="14" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18 0H2C.667 0 0 .667 0 2v10c0 1.333.667 2 2 2h16c1.327 0 1.99-.667 1.99-2 .002 0 .006-3.333.01-10 0-1.333-.667-2-2-2zM9.5 4h-7c-.333 0-.5-.167-.5-.5 0-.333.167-.5.5-.5h7c.333 0 .5.167.5.5 0 .333-.167.5-.5.5zm0 3h-7c-.333 0-.5-.167-.5-.5 0-.333.167-.5.5-.5h7c.333 0 .5.167.5.5 0 .333-.167.5-.5.5zM17 9c.667 0 1 .333 1 1s-.333 1-1 1H3c-.667 0-1-.333-1-1s.333-1 1-1zm-1-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"
                                                    fillRule="nonzero" />
                                                </svg>
                                                <span className="space_8" />
                                            УПД

                                            </button>
                                        }
                                            <span className="space_8" />
                                            {parseFloat(d.summ.replace(',', '.')) + correctsSumm <= 0 ? <></> : <button disabled={This.state.LoadingDocFile == d.id} className="request_docs_btn" onClick={
												() => { This.SendAccrSchentRequest(d.id);
												ym(95667411,'reachGoal','schet-click');
												}}>
                                            <svg width="20" height="14" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18 0H2C.667 0 0 .667 0 2v10c0 1.333.667 2 2 2h16c1.327 0 1.99-.667 1.99-2 .002 0 .006-3.333.01-10 0-1.333-.667-2-2-2zM9.5 4h-7c-.333 0-.5-.167-.5-.5 0-.333.167-.5.5-.5h7c.333 0 .5.167.5.5 0 .333-.167.5-.5.5zm0 3h-7c-.333 0-.5-.167-.5-.5 0-.333.167-.5.5-.5h7c.333 0 .5.167.5.5 0 .333-.167.5-.5.5zM17 9c.667 0 1 .333 1 1s-.333 1-1 1H3c-.667 0-1-.333-1-1s.333-1 1-1zm-1-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"
                                                    fillRule="nonzero" />
                                                </svg>
                                                <span className="space_8" />
                                            Счет
                                            </button>

                                            }
                                            <span className="space_8" />
                                            {!isHaveCorrects ? <></> : <button className="request_docs_btn corrects_btn" onClick={() => {
                                                if (isHaveCorrects)
                                                    if (!isOpen) This.setState({ active_doc_corrects: d });
                                                    else { This.setState({ active_doc_corrects: undefined }); }
                                            }}>
                                                Показать корректировки
                                            </button>

                                            }
                                        </div>
                                    </div>

                                </div>
                                {This.state.LoadingDocFile == d.id ?
                                    <Box sx={{ width: '100%', height: '5px' }}>
                                        Загрузка...
                                        <LinearProgress />
                                    </Box>
                                    : <></>}
                                {
                                    !isHaveCorrects ? <></> :
                                        <>
                                            {/*<Collapse in={This.state.active_doc_corrects == d}>*/}

                                            {
                                                d.corects.map((dd, i) => {
                                                    return <tr key={dd.dt + i} className={"accruals_detail" + (This.state.active_doc_corrects == d ? " open" : " collapsed")} >
                                                        <td>

                                                        </td>
                                                        <td className="Doc_view_table_column" >
                                                            {dd.dt}
                                                        </td>
                                                        <td className="Doc_view_table_column">
                                                            <span>
                                                                {dd.summ <= 0 ? '–' : dd.summ.toLocaleString() + ' ₽'}
                                                            </span>
                                                        </td>

                                                        <td className="Doc_view_table_column">

                                                        </td>
                                                        <td>
                                                            <span className="comment">
                                                                {dd.comment}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                })

                                            }
                                            {/*</Collapse>*/}
                                        </>
                                }
                            </>
                            )
                        })
                }

            </div>
        );
    }
    static renderDocbotInfo(This) {
        return (

            <footer className="Doc_view_bot_info">
                <div className="Doc_search_container">
                    <div className="container">
                        <div className="Doc_view_bot_contact">
                            Если возникли вопросы,<br />
                            <a href="https://sibtko.ru/svyaz" target="_blank">
                                напишите нам
                            </a>
                        </div>
                        <a href="mailto:feedback@sibtko.ru" target="_blank" className="Doc_view_bot_button">
                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.292 15.315l3.389 1.208-2.261 3.21a.616.616 0 01-.698.235.651.651 0 01-.43-.62v-4.033zM19.086.074a.603.603 0 01.651.047c.192.143.29.384.256.628l-2.291 16.087a.657.657 0 01-.294.46.608.608 0 01-.526.06l-8.736-3.115 8.59-10.8-11.101 9.905-5.212-1.859a.649.649 0 01-.421-.568.66.66 0 01.334-.628z" fill-rule="nonzero" />
                            </svg>
                            Написать письмо
                        </a>
                    </div>
                </div>
            </footer>
        )
    }
    static renderPageSwitch(instance,data, activePage) {
        if (data.length <= rowsPerPage) return (<nav className="Doc_view_pageswitch"></nav>);

        const res = [];
        const s = Math.floor((data.length - 1) / rowsPerPage);
        for (let i = 0; i <= s; i++) {
            if (activePage == i)
                res.push(<a href="#" onClick={(e) => e.preventDefault()} className="page active">{i+1}</a>)
            else
                res.push(<a href="#" onClick={(e) => { instance.handleButClick(i, e); } } className="page">{i+1}</a>)
        };

        return (
            <nav className="Doc_view_pageswitch">
                <a onClick={(e) => { instance.handleArrowClick(-1, e); }}  href="#" className="page arrow">
                    <svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.805 5.003l2.969-3.121A.811.811 0 006 1.3a.811.811 0 00-.226-.582L5.316.238A.734.734 0 004.763 0a.735.735 0 00-.553.238L.226 4.418A.812.812 0 000 5c0 .23.075.423.226.581L4.21 9.762c.15.159.335.238.553.238.218 0 .402-.08.553-.238l.458-.481A.807.807 0 006 8.703a.83.83 0 00-.226-.585l-2.97-3.115z" fillRule="nonzero" />
                    </svg>
                </a>

                {res}

                <a onClick={(e) => { instance.handleArrowClick(1, e); }} href="#" className="page arrow">
                    <svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.774 4.422L1.79.244A.71.71 0 001.24 0a.71.71 0 00-.55.244L.227.725A.807.807 0 000 1.303c0 .222.075.417.226.584L3.196 5 .226 8.12A.807.807 0 000 8.696c0 .223.075.417.226.584l.464.482a.73.73 0 00.55.237c.216 0 .4-.08.55-.237l3.984-4.179A.844.844 0 006 5a.807.807 0 00-.226-.578z" fillRule="nonzero" />
                    </svg>
                </a>
            </nav>)
    }
    static renderPayPanel(This) {
/*        const [payment, setPayment] = useState("")*/
        return (
            <div>
                <div className="Doc_view_payment_fade"/>
                <div className="Doc_view_payment_window">
                    <a href="#" onClick={This.handlecancellButtonClick} className="Close_X">
                        <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.074 4.996l3.704-3.704a.756.756 0 10-1.07-1.07L5.004 3.926 1.3.222a.756.756 0 10-1.07 1.07l3.704 3.704L.222 8.708a.756.756 0 101.07 1.07l3.712-3.712L8.708 9.77a.755.755 0 001.07 0 .756.756 0 000-1.07L6.074 4.995z" fill-rule="nonzero" />
                        </svg>
                    </a>
                    <div className="title">
                        Оплата по договору
                    </div>

                    <Form Validate onSubmit={This.CompletePayButton}>
                        <div className="field">
                            <div className="title">
                                Выбранный договор
                            </div>
                            <div className="text">
                                № {This.state.Doc.ndoc} от {This.state.DocDate}
                            </div>
                        </div>
                        <div className="field">
                            <input type="hidden" name="sum" value="710.99" />
                            <input type="hidden" name="clientid" value="100000001" />
                            <input type="hidden" name="user_result_callback" value="http://lk.sibtko.ru/pay/" />
                            <input defaultValue={This.state.balance > 0 ? 0 : -This.state.balance} onBlur={(e) => { e.target.value = e.target.value.replace(/[^0-9\.]+/g, ''); e.target.value = parseFloat(e.target.value); if (isNaN(e.target.value)) e.target.value = 0; }} type="text" name="summ" placeholder="Сумма платежа" className="input form-control" />
                            <label className="label">
                                Сумма платежа
                            </label>
                        </div>
                        <div className="field">
                            <InputGroup hasValidation >
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="    E-mail"
                                    className="mb-3"
                                >
                                    <Form.Control required
                                        type="email" name="email" placeholder="E-mail" className="input" onChange={(e, v) => { This.setState({ user_email: v }) }} value={This.state.user_email}/>
                                    <Form.Control.Feedback type="invalid">
                                        Введите почту
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </InputGroup>

                            <div className="hint">
                                Адрес электронной почты будет использован для отправки чека об оплате услуг и не будет передан третьим лицам.
                            </div>
                        </div>
                        <div className="field">
                            <InputGroup hasValidation >
                                <Form.Check required id="checkbox" className="Doc_view_payment_window_acceptance_checkbox_custom" type="checkbox" name="agree" label=" Я подтверждаю правильность указанных данных и хочу перейти к оплате услуг" />

                            </InputGroup>
                        </div>
                        <div className="Doc_view_payment_window_bot">

                            <button type="submit" name="pay" className="button" >
                                Перейти к оплате
                            </button>
                            <button type="submit" name="cancell" className="button_cancell" onClick={This.handlecancellButtonClick}>
                                Отменить
                            </button>
                        </div >
                    </Form >
                </div >
            </div >
                        )
    }
    static renderDocumetList(This) {
        let aa = This.state.ShownerdActiveDocs;
        return (<div className="documentbar">
            <div className={"documentbar_background_" + (This.state.ShownerdActiveDocs ? "active" : "closed")}>
               
                <div className="documentbar_container ">
                    {/*<div className="header">*/}
                    {/*    <div className="Centre">*/}
                    {/*        {This.state.title}*/}
                    {/*    </div>*/}
                    {/*    <div className="Centre">*/}
                    {/*        Инн: {This.state.Inn} Кпп: {This.state.Kpp}*/}
                    {/*    </div>*/}
                    {/*    <h6 >Сальдо:*/}
                    {/*        {This.state.saldo}</h6>*/}
                    {/*</div>*/}
                    <div className="space_8"></div>
                    <div className="Centre darktext">
                        {This.state.ShownerdActiveDocs ? <div>Закрытые {This.state.documents.filter((el) => !el.isActive).length}</div> : <div>Действующие {This.state.documents.filter((el) => el.isActive).length}</div>}
                    </div>
                    <div className="space"></div>
                    {
                        
                        This.state.documents.filter((d) => !((aa && d.isActive) || (!aa && !d.isActive))).map((d, i) =>
                        {
                          
                            {
                                return <div key={d.guid}>
                                    <button onClick={(e) => { This.DocumentClick(e, d) }} className={d.ndoc === This.state.Doc.ndoc ? "documentbar_document_active" : "documentbar_document"}>
                                        <div className="horizontal_group">
                                            {/*<svg width="40"*/}
                                            {/*    height="50"*/}
                                            {/*    viewBox="0 0 21 27"*/}
                                            {/*    xmlns="http://www.w3.org/2000/svg">*/}
                                            {/*    <path d="M13.438 5.656l4.406 4.625c0.438 0.469 0.813 1.344 0.813 2.031v13.688c0 0.625-0.5 1.188-1.188 1.188h-16.281c-0.625 0-1.188-0.563-1.188-1.188v-20.031c0-0.625 0.563-1.188 1.188-1.188h10.25c0.688 0 1.563 0.406 2 0.875zM2.469 25.125h13.719c0.219 0 0.406-0.188 0.406-0.406v-11.438c0-0.219-0.188-0.406-0.406-0.406h-4.906c-0.219 0-0.406-0.188-0.406-0.406v-5.219c0-0.219-0.156-0.375-0.375-0.375h-8.031c-0.219 0-0.375 0.156-0.375 0.375v17.469c0 0.219 0.156 0.406 0.375 0.406zM15.219 10.531l-1.969-2.125c-0.156-0.156-0.281-0.094-0.281 0.125v1.906c0 0.188 0.188 0.375 0.375 0.375h1.75c0.219 0 0.281-0.125 0.125-0.281zM4.875 8.719h3.875c0.406 0 0.781 0.375 0.781 0.813v0.469c0 0.406-0.375 0.781-0.781 0.781h-3.875c-0.438 0-0.813-0.375-0.813-0.781v-0.469c0-0.438 0.375-0.813 0.813-0.813zM4.875 12.906h3.875c0.406 0 0.781 0.375 0.781 0.813v0.469c0 0.406-0.375 0.781-0.781 0.781h-3.875c-0.438 0-0.813-0.375-0.813-0.781v-0.469c0-0.438 0.375-0.813 0.813-0.813zM13.719 19.156h-8.844c-0.438 0-0.813-0.344-0.813-0.781v-0.469c0-0.438 0.375-0.781 0.813-0.781h8.844c0.406 0 0.813 0.344 0.813 0.781v0.469c0 0.438-0.406 0.781-0.813 0.781zM13.719 23.438h-8.844c-0.438 0-0.813-0.344-0.813-0.75v-0.5c0-0.438 0.375-0.781 0.813-0.781h8.844c0.406 0 0.813 0.344 0.813 0.781v0.5c0 0.406-0.406 0.75-0.813 0.75z"></path>*/}
                                            {/*</svg>*/}
                                            <div className="vertical_group">
                                                
                                                <div className="document">{d.ndoc}</div>
                                                    
                                                
                                            <div className="horizontal_group space_between">
                                                <div className="document_date_small">от {d.date}</div>
                                                    <div className={parseFloat(d.saldo) >= 0 ? "saldo" : "saldo_negative"}>{(parseFloat(d.saldo) == 0 ? 0 : parseFloat(d.saldo)).toLocaleString() + ' ₽'}</div>
                                                </div>
                                                </div>
                                        </div>
                                    </button>
                                </div>;
                            }

                        })
                    }
                    {/* <div className="end"></div>*/}
                    <div className="space"></div>
                </div>
                <div className="container mx-auto mt-12">

                </div>
            </div>
        </div>
    )
    }
    static renderLeftPanel(This) {
        return (
            <div className="sidepanel">
                <div className="sidepanel_background"/>
                    <div className="navigation">
                        <div>
                            {/*<div className="header">Услуги:</div>*/}

                            <Dropdown drop='end'>
                                <Dropdown.Toggle className="drpdown" variant="success" id="dropdown-basic">
                                    Договоры
                                </Dropdown.Toggle>

                            <Dropdown.Menu className="text_drpdown_menu">
                                <Dropdown.Item onClick={(e) => { This.SetDocumentFilter(e, false) }}>Действующие  {This.state.documents.filter((el) => el.isActive).length}</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => { This.SetDocumentFilter(e, true) }}>Закрытые  {This.state.documents.filter((el) => !el.isActive).length}</Dropdown.Item>
                                </Dropdown.Menu>
                        </Dropdown>
                        <a className="text_nav_button" onClick={(e) => { This.setState({ activeLayout: 1 });  This.LoadUserRequsets(); }}>Заявки</a>
                        <a className="text_nav_button" onClick={(e) => { This.setState({ activeLayout: 2 }) }}>Реквизиты</a>
                        <a className="text_nav_button" onClick={(e) => { This.setState({ activeLayout: 3 }) }}>Уведомления ({This.state.nonReadedMessages})</a>
                        <a className="text_nav_button" onClick={(e) => { This.setState({ activeLayout: 5 }) }}>Профиль</a>
                        {/*    <Dropdown drop='end'>*/}
                        {/*        <Dropdown.Toggle className="drpdown" variant="success" id="dropdown-basic">*/}
                        {/*            Заявки*/}
                        {/*        </Dropdown.Toggle>*/}

                        {/*        <Dropdown.Menu className="drpdown_menu">*/}
                        {/*            <Dropdown.Item onClick={(e) => {  }}>*/}
                        {/*                Заявки такието</Dropdown.Item>*/}
                        {/*            <Dropdown.Item onClick={(e) => { }}>*/}
                        {/*                Заявки сякието</Dropdown.Item>*/}
                        {/*            <Dropdown.Item onClick={(e) => {  }}>*/}
                        {/*                и т.д</Dropdown.Item>*/}
                        {/*        </Dropdown.Menu>*/}
                        {/*</Dropdown>*/}
                        {/*<Dropdown drop='end' autoClose="outside">*/}
                        {/*    <Dropdown.Toggle className="drpdown drpdown_hideArrow" variant="success" id="dropdown-basic">*/}
                        {/*        Реквизиты для оплаты*/}
                        {/*    </Dropdown.Toggle>*/}

                        {/*    <Dropdown.Menu className="drpdown_menu">*/}
                        {/*        <Dropdown.Item onClick={(e) => { navigator.clipboard.writeText(This.state.titleBank); e.preventDefault(); }}>*/}
                        {/*            {This.state.titleBank} ({This.state.gorod})*/}
                        {/*        </Dropdown.Item>*/}
                        {/*        <Dropdown.Divider />*/}
                        {/*        <Dropdown.Item onClick={(e) => { navigator.clipboard.writeText(This.state.raschetSchet); e.preventDefault(); }}>*/}
                        {/*            Р/С: {This.state.raschetSchet}*/}
                        {/*        </Dropdown.Item>*/}
                        {/*        <Dropdown.Item onClick={(e) => { navigator.clipboard.writeText(This.state.korSchet); e.preventDefault(); }}>*/}
                        {/*            К/С: {This.state.korSchet}*/}
                        {/*        </Dropdown.Item>*/}
                        {/*        <Dropdown.Item onClick={(e) => { navigator.clipboard.writeText(This.state.bik); e.preventDefault(); }}>*/}
                        {/*            БИК: {This.state.bik}*/}
                        {/*        </Dropdown.Item> */}
                        {/*    </Dropdown.Menu>*/}
                        {/*</Dropdown>*/}
                        {/*<Dropdown drop='end'>*/}
                        {/*    <Dropdown.Toggle className="drpdown drpdown_hideArrow" variant="success" id="dropdown-basic">*/}
                        {/*        Сообщения {This.state.activePage == 0 ?"(114) º": "( 0 )"}*/}
                        {/*    </Dropdown.Toggle>*/}

                        {/*    <Dropdown.Menu className="drpdown_menu">*/}
                        {/*        <Dropdown.Item onClick={(e) => {  }}>*/}
                        {/*            Заявки такието</Dropdown.Item>*/}
                        {/*        <Dropdown.Item onClick={(e) => {  }}>*/}
                        {/*            Заявки сякието</Dropdown.Item>*/}
                        {/*        <Dropdown.Item onClick={(e) => { }}>*/}
                        {/*            и т.д</Dropdown.Item>*/}
                        {/*    </Dropdown.Menu>*/}
                        {/*</Dropdown>*/}
                        {/*<Dropdown drop='end'>*/}
                        {/*    <Dropdown.Toggle className="drpdown drpdown_hideArrow drpdown_edo" variant="success" id="dropdown-basic">*/}
                        {/*        ЭДО {This.state.activePage == 0 ? "✔" : " ?"}*/}
                        {/*    </Dropdown.Toggle>*/}

                        {/*    <Dropdown.Menu className="drpdown_menu">*/}
                        {/*        <Dropdown.Item onClick={(e) => { This.SetDocumentFilter(e, true) }}>*/}
                        {/*            <a href="#">ссылки ...№";%№!";№*(/hhsoitpapo&f9mv2</a>*/}
                        {/*        </Dropdown.Item>*/}
                        {/*        <Dropdown.Item onClick={(e) => { This.SetDocumentFilter(e, false) }}>*/}
                        {/*            <a href="#">ссылки ...№";%№*(/hhsoitpap</a>*/}
                        {/*        </Dropdown.Item>*/}
                        {/*        <Dropdown.Item onClick={(e) => { This.SetDocumentFilter(e, false) }}>*/}
                        {/*            <a href="#">ссылки ...№";%№!";№*apo&f9mv2</a>*/}
                        {/*        </Dropdown.Item>*/}
                        {/*    </Dropdown.Menu>*/}
                        {/*</Dropdown>*/}

                    </div>
                    <div className="info_footer">
                        < div className="header">
                        </div>
                        <div className="info_buttons">
                            {/*<button className="nav_button">Сообщения(#)</button>*/}
                            {/*<button className="nav_button">Реквизиты для оплаты</button>*/}
                            {/*<a className="text_nav_button" onClick={(e) => { This.setState({ activeLayout: 5 }) }}>Профиль</a>*/}
                        </div>
                        {/*<div className="contacts">*/}

                        {/*    <div className="text"> Адрес:################## ########.</div>*/}
                        {/*    <div className="text"> Телефон:+7(№№№)-№№№-№№-№№.</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                </div>
          
        );
    }
    static renderBottomInfo(This) {
        return (
            //<div className="footer_info">
            //    <div className="contacts">
            //        <div className="text">Адрес:ООО «Чистый Город Кемерово», 650036, г. Кемерово, ул. Мирная, 9, офис 110 </div>
            //        <div className="text">Телефон:8-800-600-14-20.        </div>
            //    </div>
            //</div>
            <></>
        );
    }
    static renderMessagePanel(This) {
        //if (This.state.isMessageWindowOpen)
        return (
                
            <Fade in={This.state.isMessageWindowOpen} dimension="width">
                {
                    (This.state.currentMessage.length > 0)?
                    <div className="message_read_panel">

                        <div className="header">
                            {This.state.messages[This.state.currentMessage].header}
                            <CloseButton className="x_button" onClick={(e) => { This.setState({ isMessageWindowOpen: false }) }} />
                        </div>
                        <div className="line" />
                        {/* <div className="message_body" dangerouslySetInnerHTML={__html: This.state.messages[This.state.currentMessage].message}/>*/}
                        <div className="message_body" dangerouslySetInnerHTML={{ __html: This.state.messages[This.state.currentMessage].message }} />
                        {/*    {This.state.messages[This.state.currentMessage].message}*/}
                        {/*</div>*/}
                    </div>:<></>
                }
                </Fade>
        );
        //return null;
    }
    change = eventkey => {
        // a.persist();
        alert(`you chosen: ${eventkey}`);
    };
    static renderObjectsPage(This) {
        console.log(This.state.objects);
        if (!This.state.isObjectsLoading && This.state.objects == undefined) {
            if (!This.state.isObjectfailed2Load)
            {
                This.LoaddocObjects(This.state.Doc.guid);
            }
        }
        return <div className="Doc_view_table">
            <Table striped bordered hover variant={This.state.isthemeDark? "dark":"light"}>
                <thead className="top_sticky">
                    <tr>
                        <th>№</th>
                        <th>Объект</th>
                        <th>Адрес</th>
                    </tr>
                </thead>
                <tbody>
                    {This.state.isObjectsLoading ?<Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          
                    /> :
                        This.state.objects == undefined ? <span>Что-то пошло не так, попробуйте еще раз или позднее</span> :
                            This.state.objects.map((d, i) => {
                                return <tr id={d.title}>
                                    <td>{i+1}</td>
                                    <td className="object_table_name">{d.title}</td>
                                    <td className="object_table_address">{d.address}</td>
                                </tr>
                            })
                       
                    }
                </tbody>
            </Table>
        </div>
    }
    static renderHat(This) {
        return (
           
                <header className="hat">
                    <div className="hat_container">
                        <div className="hat_background">
                            <div className="hat_header_container">
                            <div className="header">
                                <img src={This.state.isthemeDark ? require('../Images/logo_site_light3.png'):require('../Images/logo_site.png')} alt="" width="140" height="83" className="Ur_doc_page_logo" />
                                    <div className="title">
                                        Клиент: {This.state.Title}
                                        <div className="inn_kpp">ИНН/КПП: {This.state.Inn} / {This.state.Kpp}
                                        </div>
                                    Общее сальдо: {This.state.saldo.toLocaleString()} ₽{/* Просрочено: ####*/}
                                    </div>
                                </div>
                            <div className="logout_container">
                                <a href="https://sibtko.ru/" rel="nofollow" className="back_to_main_text">
                                   
                                    Вернуться на главную
                                </a>
                                <form>
                                    <a type="submit" href="/urUserDocs" className="exit_text">Выход</a>
                                </form>

                                <a href="#" onClick={() => { This.setState({ changepass: true }) }}>изменить пароль</a>
                                <label className="dark_theme"><a>Темная тема</a> &emsp;<input className="" type="checkbox" checked={This.state.isthemeDark} onChange={(e) => {

                                    This.setState({ isthemeDark: e.target.checked });
                                    localStorage.setItem(StorageKey, e.target.checked);
                                    document.documentElement.setAttribute('data-theme',
                                        e.target.checked ?"dark":"light");
                                }} />
                                </label>
                                </div>
                            </div>

                            {/*<div className="navigation">*/}

                            {/*    <Dropdown>*/}
                            {/*        <Dropdown.Toggle variant="success" id="dropdown-basic">*/}
                            {/*            Договоры*/}
                            {/*        </Dropdown.Toggle>*/}

                            {/*        <Dropdown.Menu>*/}
                            {/*            <Dropdown.Item onClick={(e) => {This.SetDocumentFilter(e,true)}}>Активные</Dropdown.Item>*/}
                            {/*            <Dropdown.Item onClick={(e) => { This.SetDocumentFilter(e, false) }}>Закрытые</Dropdown.Item>*/}
                            {/*        </Dropdown.Menu>*/}
                            {/*    </Dropdown>*/}
                            {/*    <button className="nav_button"></button>*/}
                            {/*    <button className="nav_button"></button>*/}
                            {/*    <button className="nav_button"></button>*/}
                            {/*    <button className="nav_button"></button>*/}
                            {/*    <button className="nav_button"></button>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </header>
        )
    }
//#endregion
    render() {
        let SearchPage = !this.state.IsDocFinded ? UrUserDocs.renderAuntheficaionPage(this): (<div></div>);
        let DocPage = this.state.IsDocFinded ? UrUserDocs.renderMainFrame(this) : (<div></div>);
        let PaymentWindow = this.state.PayWindowActive ? UrUserDocs.renderPayPanel(this):(<div></div>)
        return (
            <div className="NonScroll">
                {/* <SignUp/>*/}
                <ChangePassModal pass={this.state.currentPass} show={this.state.changepass} onHide={() => this.setState({ changepass: false })} onSubmit={this.handleChangePass}/>
                {PaymentWindow}
                {SearchPage}
                {DocPage}
            </div>

        );
    }













    



    async LoadUserRequsets() {
        this.setState({ isRequestsLoading: true, isRequestsfailed2Load: false });
        //   this.setState({ isContactsLoading: true });
        fetch((process.env.REACT_APP_USE_API_URL == true ? process.env.REACT_APP_API_URL : '') + 'api/requests/' + this.state.Guid
        ).then(async (response) => {
            if (response.status === 200) {

                let data = await response.json();
                console.log(data);

                this.setState({ requests: data, isRequestsLoading: false, isRequestsfailed2Load: false });
            }
            else { this.setState({ isRequestsLoading: false, isRequestsfailed2Load: true }); }
        }).catch(e => { console.error('EXCEPTION: ', e); this.setState({ isRequestsLoading: false, isRequestsfailed2Load: true }); });

    }


    async LoaddocObjects(docGuid) {
       // console.log(dateFrom, dateTo);
        //console.log(JSON.stringify({ code: code, email: email, dateFrom: dateFrom, dateTo: dateTo, userGuid: this.state.Guid, docGuid: DocGuid }));
        this.setState({isObjectsLoading: true, isObjectfailed2Load: false });
     //   this.setState({ isContactsLoading: true });
        fetch((process.env.REACT_APP_USE_API_URL == true ? process.env.REACT_APP_API_URL : '') + 'api/documents/objects/' + docGuid
        ).then(async (response) => {
            if (response.status === 200) {

                let data =await response.json();
                console.log(data);

                this.setState({ objects: data , isObjectsLoading:false, isObjectfailed2Load:false});
            }
            else { this.setState({ isObjectsLoading: false, isObjectfailed2Load: true }); }
        }).catch(e => { console.error('EXCEPTION: ', e); this.setState({  isObjectsLoading: false, isObjectfailed2Load: true }); });
      
    }

    async SendDocRequest(
        // code, 
        email,
        dateFrom, dateTo
        , DocGuid
    ) {
      //  console.log(dateFrom, dateTo);
        //console.log(JSON.stringify({ code: code, email: email, dateFrom: dateFrom, dateTo: dateTo, userGuid: this.state.Guid, docGuid: DocGuid }));
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                //code: code, email: email,
                dateFrom: dateFrom, dateTo: dateTo,
                userGuid: this.state.Guid, docGuid: DocGuid
                ,email: email
            })
            /*            body: "message: \"" + message+"\""*/
        };
        //alert("Сейчас начнется загрузка акта сверки...");
        this.setState({ isActSverkiNotFound: false });
        this.setState({ isContactsLoading: true });
        fetch((process.env.REACT_APP_USE_API_URL == true ? process.env.REACT_APP_API_URL : '') + 'api/documents/request'
            , requestOptions
        ).then(async (response) => {
            if (response.status === 200) {
                this.setState({ isContactsLoading: false, show_receiveMessageWindow: false, showDetailemailModal:false, ReceiveMessageTitle: '' });
                this.LoadProfileInfo();
                //alert("В ближайшее время результаты прийдут на указанную почту: `"+email+"`")
                if (email == null) {
                    response.blob().then(blob => {

                        console.log(blob);
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        a.href = url;
                        // the filename you want
                        a.download = "Акт сверки";
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);

                    })
                        .catch(() => { alert("oh no!"); });;
                }
                else {
                    alert("Успешно, документ скоро прийдет на почту");
                }
            }
            else if (response.status === 666) {
                await timeout(2250);
               // alert("Сейчас выгрузки временно недоступны, попробуйте позже...");
                
                this.setState({
                    isContactsLoading: false, IsDocNotFined: true,
                    isActSverkiNotFound: true, showDetailemailModal: true
                }); 
            }
            else { this.setState({ isContactsLoading: false, IsDocNotFined: true }); this.setState({ isContactsLoading: false }); }
        }).catch(e => console.error('EXCEPTION: ', e));
    }
    async SendAccrSchentRequest(
        // code, email,
        // dateFrom, dateTo,
        AccrId
    ) {
        //  console.log(dateFrom, dateTo);
        //console.log(JSON.stringify({ code: code, email: email, dateFrom: dateFrom, dateTo: dateTo, userGuid: this.state.Guid, docGuid: DocGuid }));
        const requestOptions = {
            method: 'POST',
        };
        //  alert("Сейчас начнется загрузка документа...");

        this.setState({ LoadingDocFile: AccrId });

        fetch((process.env.REACT_APP_USE_API_URL == true ? process.env.REACT_APP_API_URL : '') + 'api/documents/accschet?req=' + AccrId
            , requestOptions
        ).then(async (response) => {
            this.setState({ LoadingDocFile: undefined });
            if (response.status === 200) {
                
                this.LoadProfileInfo();
                //alert("В ближайшее время результаты прийдут на указанную почту: `"+email+"`")
                response.blob().then(blob => {

                    console.log(blob);
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    // the filename you want
                    a.download = "Счет";
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);

                })
                    .catch(() => { alert("oh no!"); });;
            }
            else if (response.status === 666) {
                await timeout(2250);
                
              //  alert("Сейчас выгрузки временно недоступны, попробуйте позже...");
               
            }
        }).catch(e => console.error('EXCEPTION: ', e));
    }
    async SendUPDRequest(
        // code, email,
        // dateFrom, dateTo,
        AccrId
    ) {
        //  console.log(dateFrom, dateTo);
        //console.log(JSON.stringify({ code: code, email: email, dateFrom: dateFrom, dateTo: dateTo, userGuid: this.state.Guid, docGuid: DocGuid }));
        const requestOptions = {
            method: 'POST',
        };
        //  alert("Сейчас начнется загрузка документа...");
        this.setState({ LoadingDocFile: AccrId });
        fetch((process.env.REACT_APP_USE_API_URL == true ? process.env.REACT_APP_API_URL : '') + 'api/documents/upd?req=' + AccrId
            , requestOptions
        ).then(async (response) => {
            this.setState({ LoadingDocFile: undefined });
            if (response.status === 200) {

                this.LoadProfileInfo();
                //alert("В ближайшее время результаты прийдут на указанную почту: `"+email+"`")
                response.blob().then(blob => {

                    console.log(blob);
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    // the filename you want
                    a.download = "Упд";
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);

                })
                    .catch(() => { alert("oh no!"); });;
            }
            else if (response.status === 666) {
                await timeout(2250);

                //  alert("Сейчас выгрузки временно недоступны, попробуйте позже...");

            }
        }).catch(e => console.error('EXCEPTION: ', e));
    }

    async AddNewContact(contact, comment) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ comment: comment, contact:contact, userGuid:this.state.Guid })
            /*            body: "message: \"" + message+"\""*/
        };
        this.setState({ isContactsLoading: true });
        fetch((process.env.REACT_APP_USE_API_URL == true ? process.env.REACT_APP_API_URL : '') + 'api/profile/add'
            , requestOptions
        ).then(async (response) => {
            if (response.status === 200) {
                this.setState({ isContactsLoading: false, ShowAddContactModal: false,modal_addContact_contact:'', modal_deprecate_commentary:'' });
                this.LoadProfileInfo();
            }
            else { this.setState({ isContactsLoading: false, IsDocNotFined: true }); this.setState({ isContactsLoading: false }); }
        }).catch(e => console.error('EXCEPTION: ', e))
    }
    async DeprecateContact(message) {
        //console.log(message);
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(message)
            /*            body: "message: \"" + message+"\""*/
        };
        this.setState({ isContactsLoading: true });
        fetch((process.env.REACT_APP_USE_API_URL == true ? process.env.REACT_APP_API_URL : '') + 'api/profile/deprecate/' + this.state.contact2Deprecate.guid
            , requestOptions
        ).then(async (response) => {
            if (response.status === 200) {
                this.setState({ isContactsLoading: false, show: false });
                this.LoadProfileInfo();
            }
            else { this.setState({ isContactsLoading: false, IsDocNotFined: true }); this.setState({ isContactsLoading: false }); }
        }).catch(e => console.error('EXCEPTION: ', e))
    }

    async LoadProfileInfo() {
        this.setState({ isKaContactsLoading: true });
        fetch((process.env.REACT_APP_USE_API_URL == true ? process.env.REACT_APP_API_URL : '') + 'api/profile/info/' + this.state.Guid/* this.state.Doc*/).then(async (response) => {
            if (response.status === 200) {
                let data = await response.json();
                this.setState({ contacts: data });
                this.setState({ isKaContactsLoading: false });
            }
            else { this.setState({ failedToLoad: true, IsDocNotFined: true });this.setState({ isKaContactsLoading: false }); }
        }).catch(e => console.error('EXCEPTION: ', e))
    }

    async CheckLogAPass(login, pass) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({login:login,password:pass})
            /*            body: "message: \"" + message+"\""*/
        };
        fetch((process.env.REACT_APP_USE_API_URL == true ? process.env.REACT_APP_API_URL : '') + 'user', requestOptions).then(async (respons) => {
            if (respons.ok) {
				
                this.loadTheme();
                const jsondata = await respons.json();
                console.log(respons);
                const doc = jsondata.data[0];
                const guid = jsondata.guid;
                const title         = jsondata.title;
                const inn           = jsondata.inn;
                const cpp           = jsondata.kpp;
                const titleBank     = jsondata.titleBank;
                const korSchet      = jsondata.korSchet;
                const gorod         = jsondata.gorod;
                const raschetSchet  = jsondata.raschetSchet;
                const bik = jsondata.bik;
                const ADO = jsondata.ado;
				ym(95667411, 'userParams', {
    title: title,
    UserID: inn
});
ym(95667411,'reachGoal','login-succes');
                //alert(ADO);
                /*let ADO = "";*/
                //axios.get('/api/ADO/' + guid)
                //    .then(function (response) {
                //        const jd = response.json();
                //        ADO = jd.ADO;
                //        alert(ADO);
                //    })
        
           
                let sal = 0;
                console.log("doc: " + doc);
                jsondata.data.forEach(e => sal += parseFloat(e.saldo));
                await this.setState({
                    Title: title,
                    Inn: inn,
                    Kpp: cpp,
                    IsAuthorizationCorrect: true,
                    documents: jsondata.data,
                    Doc: doc,
                    saldo: sal,
                    titleBank    :titleBank    ,
                    korSchet     :korSchet     ,
                    gorod        :gorod        ,
                    raschetSchet :raschetSchet ,
                    bik: bik,
                    Guid: guid,
                    ADO: ADO
                });
                this.loadData(doc.guid,true);
            }
            else {ym(95667411,'reachGoal','login-failed'); alert("Не верный логин или пароль!"); }
        });
    }
    async loadData(document, initialLoad) {
        
        this.setState({ isDocumentInfoLoading: true });
        fetch((process.env.REACT_APP_USE_API_URL==true?process.env.REACT_APP_API_URL:'') + 'api/documents/ur/' + document/* this.state.Doc*/).then(async (response) => {
            if (response.status === 200) {
                /* const response = await fetch('sber' + '?user=' + this.state.Doc+'&r=1');*/
                let resp = await response.json();
                let data = resp.accr;
                const date = resp.info.date;
                let muCount = 0;
                var tmp = 0.;
                data.forEach(e => tmp += e.summ.replace(',', '.') * 1 - e.summ2.replace(',', '.') * 1);
                tmp = tmp.toFixed(2)
                tmp = Math.round(-tmp * 100, 2) / 100;
                if (initialLoad == true) data = undefined;
                this.setState({ isDocumentInfoLoading: false });
                this.setState({ data: data, docinfo: resp.info, loading: false, balance: tmp, failedToLoad: false, IsDocFinded: true, DocDate: date});

                fetch((process.env.REACT_APP_USE_API_URL == true ? process.env.REACT_APP_API_URL : '') + 'api/messages' + '/' + this.state.Guid).then(async (respons) => {
                    const messages = await respons.json();
                    messages.forEach(e => {
                        muCount += e.isReaded != "False" ? 0 : 1
                        var re = /([^\"=]{2}|^)((https?|ftp):\/\/\S+[^\s.,> )\];'\"!?])/;
                        var subst = '$1<a href="$2" target="_blank">$2</a>';
                        e.message = e.message.replace(re, subst);
                    });

                    var tmp = 0.;
                    data.forEach(e => tmp += e.summ.replace(',', '.') * 1 - e.summ2.replace(',', '.') * 1);
                    tmp = tmp.toFixed(2)
                    tmp = Math.round(-tmp * 100, 2) / 100;
                    if (initialLoad == true) data = undefined;
                   // this.setState({ isDocumentInfoLoading: false });
                    this.setState({ messages: messages, loading: false, failedToLoad: false, nonReadedMessages: muCount });
                }).catch(e => console.error('EXCEPTION: ', e))
                
            }
            else { this.setState({ failedToLoad: true, IsDocNotFined: true, isDocumentInfoLoading: false }); }
        }).catch(e => console.error('EXCEPTION: ', e))


    }

    async SendReadMessage(message) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: "\"" + message + "\""
/*            body: "message: \"" + message+"\""*/
        };
        await fetch((process.env.REACT_APP_USE_API_URL == true ? process.env.REACT_APP_API_URL : '') + 'api/messages', requestOptions);
    }
    async SberButton(email,summ) {
        //const respons = await fetch('sber' + '/' + this.state.Doc + '?summ=' + (this.state.balance < 0 ? -this.state.balance : 2.25));
        const respons = await fetch((process.env.REACT_APP_USE_API_URL == true ? process.env.REACT_APP_API_URL : '') + 'sber' + '/' + this.state.Doc.ndoc + '?summ=' + summ + '&email=' + email + '&backEndPoint=urUserDocs');
        if (respons.ok) {
            const data = respons.text().then(function (text) {
  /*              console.log(text);*/
                if (text == '0') { console.error("AAAAAA"); return; }
                else
                    window.location.replace(text);
            });;

        }
    }
    async SendPasswordChange(pass) {
        axios.put(`api/users?guid=${this.state.Guid}&pass=${pass}`)
    }
    async SendAdoRequest(operator, message) {
        
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: "\"" + `Оператор: ${operator} \n ${message}` + "\""
            /*            body: "message: \"" + message+"\""*/
        };
        await fetch(`/api/ado?guid=${this.state.Guid}`, requestOptions);
        //axios.request(`/api/ado?guid=${this.state.Guid}`,
        //    {

        //        headers: { 'Content-Type': 'application/json' },
        //        body: ("\"" + `Оператор: ${operator} \n ${message}` + "\"")
        //    }

        //).then(req => { });
    }
}
